import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';

interface PromotionAdminProps {
}

export default (props: PromotionAdminProps) => {
    return (
        <React.Fragment>
            <div className='d-flex justify-content-end mb-4'>
                <NavLink exact to={'/editpromotions'}>
                    <Button color="primary">Manage Promotions</Button>
                </NavLink>
            </div>
        </React.Fragment>
    );
};