import * as React from 'react';
import { Col, FormGroup, FormText, Input, Label} from 'reactstrap';

interface EventEditorTitleProps {
    title: string,
    setTitle: React.Dispatch<React.SetStateAction<string>>
}

export default (props: EventEditorTitleProps) => {
    return (
        <React.Fragment>
            <FormGroup row>
                <Label for="title" sm={2}>
                    Title
                </Label>
                <Col sm={10}>
                    <Input name='title' id='title' value={props.title} onChange={(e) => props.setTitle(e.target.value)} />
                </Col>
            </FormGroup>
        </React.Fragment>
    );
};