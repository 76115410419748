import { ForumPermission } from "./ForumPermission";

export interface IForumTreeObject {
    id: number,
    entityId: number,
    name: string,
    description: string,
    permission: ForumPermission
    locked: boolean,
    hidden: boolean,
    moderated: boolean,
    children: IForumTreeObject[]
}

export class ForumTreeObject implements IForumTreeObject {
    id: number;
    entityId: number;
    name: string;
    description: string;
    permission: ForumPermission;
    locked: boolean;
    hidden: boolean;
    moderated: boolean;
    children: IForumTreeObject[];
}