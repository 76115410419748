import * as React from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';
import { EventsPageMode } from './EventListing';

interface EventAdminProps {
    mode: EventsPageMode,
    setMode: React.Dispatch<React.SetStateAction<EventsPageMode>>
}

export default (props: EventAdminProps) => {
    return (
        <React.Fragment>
            <div className='d-flex my-2'>
                <Button color="primary" className='me-2' onClick={e => props.setMode(EventsPageMode.AllEvents)} active={props.mode === EventsPageMode.AllEvents}>All Events</Button>
                <Button color="primary" className='me-2' onClick={e => props.setMode(EventsPageMode.MyEvents)} active={props.mode === EventsPageMode.MyEvents}>My Events</Button>
                <NavLink className='ms-auto' exact to={'/newevent'}>
                    <Button color="primary">New Event</Button>
                </NavLink>
            </div>
        </React.Fragment>
    );
};