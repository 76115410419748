import * as React from 'react';
import { NavLink } from 'react-router-dom';

interface ForumHeaderProps {
    forumname: string,
    forumgroupname: string
}

export default (props: ForumHeaderProps) => {
    return (
        <React.Fragment>
            <div className='thread-header'>
                <div className='thread-header-title'>
                    {props.forumname}
                </div>
                <div className='thread-header-description'>
                    {props.forumgroupname}
                </div>
            </div>
        </React.Fragment>
    );
};