import * as React from 'react';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Card, CardBody, CardImg, CardSubtitle, CardText, CardTitle, Col, Row } from 'reactstrap';
import { IPromotedItemDto, PromotedType } from '../../DoomCentral';
import { colorForResourceType, routeForResourceType } from '../../helpers';

interface PromotedProps {
    promotedItems: IPromotedItemDto[],
    loading: boolean
}

export default (props: PromotedProps) => {
    return (
        <React.Fragment>
            {props.promotedItems && props.promotedItems.length > 0 && (
                <NavLink to={`/${routeForResourceType(props.promotedItems[0].promotedType)}/${props.promotedItems[0].resourceId}`} className="text-decoration-none">
                    <div className="promobg bg-image p-4 p-md-5 mb-4 rounded text-bg-dark" style={{ backgroundImage: `url(${props.promotedItems[0].promotedImageUrl})` }}>
                        <Col className="px-0" md={6}>
                            {props.loading && !props.promotedItems && (
                                <div className="placeholder-glow">
                                    <div className='module-item placeholder-glow"'>
                                        <span className='module-item-title placeholder col-8'></span>
                                    </div>
                                </div>
                            )}
                            {props.promotedItems[0] && (
                                <strong className={`d-inline-block text-capitalize mb-2 ${colorForResourceType(props.promotedItems[0].promotedType)}`}>
                                    {routeForResourceType(props.promotedItems[0].promotedType)}
                                </strong>
                            )}
                            <div className="mb-1 text-muted">
                                {props.promotedItems[0] && new Intl.DateTimeFormat('default', { month: 'short', day: 'numeric' }).format(new Date(props.promotedItems[0].created))}
                            </div>
                            <h1 className="display-4 fst-italic">
                                {props.promotedItems && props.promotedItems[0] && props.promotedItems[0].promotedTitle}
                            </h1>
                            {props.loading && !props.promotedItems && (
                                <div className="placeholder-glow">
                                    <div className='module-item placeholder-glow"'>
                                        <span className='module-item-title placeholder col-8'></span>
                                    </div>
                                </div>
                            )}
                            <p className="lead mt-3 mb-auto">
                                {props.promotedItems && props.promotedItems[0] && props.promotedItems[0].promotedBlurb}
                            </p>
                        </Col>
                    </div>
                </NavLink>
            )}
            {(!props.promotedItems || props.promotedItems.length <= 0) && (
                <div className="promobg bg-image p-4 p-md-5 mb-4 rounded text-bg-dark" style={{ backgroundImage: `url(https://picsum.photos/id/135/318/180?grayscale&blur=10)` }}>
                    <Col className="px-0" md={6}>
                        {props.loading && !props.promotedItems && (
                            <div className="placeholder-glow">
                                <div className='module-item placeholder-glow"'>
                                    <span className='module-item-title placeholder col-8'></span>
                                </div>
                            </div>
                        )}
                        <h1 className="display-4 fst-italic">
                            {props.promotedItems && !props.promotedItems[0] && (<>No promotion</>)}
                        </h1>
                        {props.loading && !props.promotedItems && (
                            <div className="placeholder-glow">
                                <div className='module-item placeholder-glow"'>
                                    <span className='module-item-title placeholder col-8'></span>
                                </div>
                            </div>
                        )}
                        <p className="lead my-3">
                            {props.promotedItems && !props.promotedItems[0] && (<>No promotion.</>)}
                        </p>
                    </Col>
                </div>
            )}
            <Row className="mb-2">
                <Col md={6} className="mb-4">
                    <Row className='g-0 p-4 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 h-100 position-relative'>
                        <Col className="d-flex flex-column position-static">
                            {props.loading && !props.promotedItems && (
                                <div className="placeholder-glow">
                                    <div className='module-item placeholder-glow"'>
                                        <span className='module-item-title placeholder col-4'></span>
                                        <span className='module-item-title placeholder col-6'></span>
                                        <span className='module-item-title placeholder col-4'></span>
                                        <span className='module-item-title placeholder col-12'></span>
                                        <span className='module-item-title placeholder col-12'></span>
                                        <br />
                                        <span className='module-item-title placeholder col-6'></span>
                                    </div>
                                </div>
                            )}
                            {props.promotedItems && (
                                <>
                                    {props.promotedItems[1] && (
                                        <strong className={`d-inline-block text-capitalize mb-2 ${colorForResourceType(props.promotedItems[1].promotedType)}`}>
                                            {routeForResourceType(props.promotedItems[1].promotedType)}
                                        </strong>
                                    )}
                                    <div className="mb-1 text-muted">
                                        {props.promotedItems[1] && new Intl.DateTimeFormat('default', { month: 'short', day: 'numeric' }).format(new Date(props.promotedItems[1].created))}
                                    </div>
                                    <h3 className="mb-0">
                                        {props.promotedItems[1] && props.promotedItems[1].promotedTitle}
                                        {!props.promotedItems[1] && (<>No promotion.</>)}
                                    </h3>
                                    <div className="card-text mb-auto">
                                        {props.promotedItems[1] && props.promotedItems[1].promotedBlurb}
                                    </div>
                                    {props.promotedItems[1] && (
                                        <NavLink
                                            to={`/${routeForResourceType(props.promotedItems[1].promotedType)}/${props.promotedItems[1].resourceId}`}
                                            className='stretched-link'>
                                            Continue reading
                                        </NavLink>
                                    )}
                                </>
                            )}
                        </Col>
                        <Col className="d-none d-lg-flex">
                            {props.promotedItems && props.promotedItems.length > 1 && (
                                <CardImg
                                    alt="Promotion image"
                                    className="img-cover"
                                    src={props.promotedItems && props.promotedItems[1] && props.promotedItems[1].promotedImageUrl}
                                    width="100%"
                                />
                            )}
                            {(!props.promotedItems || props.promotedItems.length <= 1) && (
                                 <CardImg
                                    alt="Loading placeholder..."
                                    src='https://picsum.photos/id/135/318/180?grayscale&blur=10'
                                    width="100%"
                                />
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col md={6} className="mb-4">
                    <Row className='g-0 p-4 border rounded overflow-hidden flex-md-row shadow-sm h-md-250 h-100 position-relative'>
                        <Col className="d-flex flex-column position-static">
                            {props.loading && !props.promotedItems && (
                                <div className="placeholder-glow">
                                    <div className='module-item placeholder-glow"'>
                                        <span className='module-item-title placeholder col-4'></span>
                                        <span className='module-item-title placeholder col-6'></span>
                                        <span className='module-item-title placeholder col-4'></span>
                                        <span className='module-item-title placeholder col-12'></span>
                                        <span className='module-item-title placeholder col-12'></span>
                                        <br />
                                        <span className='module-item-title placeholder col-6'></span>
                                    </div>
                                </div>
                            )}
                            {props.promotedItems && (
                                <>
                                    {props.promotedItems[2] && (
                                        <strong className={`d-inline-block text-capitalize mb-2 ${colorForResourceType(props.promotedItems[2].promotedType)}`}>
                                            {routeForResourceType(props.promotedItems[2].promotedType)}
                                        </strong>
                                    )}
                                    <div className="mb-1 text-muted">
                                        {props.promotedItems[2] && new Intl.DateTimeFormat('default', { month: 'short', day: 'numeric' }).format(new Date(props.promotedItems[2].created))}
                                    </div>
                                    <h3 className="mb-0">
                                        {props.promotedItems[2] && props.promotedItems[2].promotedTitle}
                                        {!props.promotedItems[2] && (<>No promotion.</>)}
                                    </h3>
                                    <div className="card-text mb-auto">
                                        {props.promotedItems[2] && props.promotedItems[2].promotedBlurb}
                                    </div>
                                    {props.promotedItems[2] && (
                                        <NavLink
                                            to={`/${routeForResourceType(props.promotedItems[2].promotedType)}/${props.promotedItems[2].resourceId}`}
                                            className='stretched-link'>
                                            Continue reading
                                        </NavLink>
                                    )}
                                </>
                            )}
                        </Col>
                        <Col className="d-none d-lg-flex">
                            {props.promotedItems && props.promotedItems.length > 2 && (
                                <CardImg
                                    alt="Promotion image"
                                    className="img-cover"
                                    src={props.promotedItems && props.promotedItems[2] && props.promotedItems[2].promotedImageUrl}
                                    width="100%"
                                />
                            )}
                            {(!props.promotedItems || props.promotedItems.length <= 2) && (
                                <CardImg
                                    alt="Loading placeholder..."
                                    src='https://picsum.photos/id/135/318/180?grayscale&blur=10'
                                    width="100%"
                                />
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    );
};