import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { ForumsClient, IForumListingVm, ISidebarVm, SidebarClient } from '../../DoomCentral';
import { IErrorResponse } from '../../ErrorResponse';
import { ErrorContext } from '../../state';
import HelmetPages from '../helmet/HelmetPages';
import Sidebars from '../sidebars/Sidebars';
import NewThreadBoxContainer from './NewThreadBoxContainer';
import NewThreadOptions from './NewThreadOptions';
import NewThreadTitle from './NewThreadTitle';
import SubmitThreadButton from './SubmitThreadButton';
import useQueryParam from '../../useQueryParam';

export default () => {
    const error = useContext(ErrorContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [forumId, setforumId] = useState<number>(0);
    const [title, setTitle] = useState<string>("");
    const [post, setPost] = useState<string>("");

    const [forumIdParam, setForumIdParam] = useQueryParam('forumId', '');

    const [forumList, setForumList] = useState<IForumListingVm>();

    // Initial load for the thread page.
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new ForumsClient();
                const response = await client.getForumListing()
                    .then(response => response.toJSON() as Promise<ISidebarVm>);
                setForumList(response);
            } catch (e) {
                error.setError(e.response as IErrorResponse);
            }
            setLoading(false);
        };

        fetchData();

    }, []);

    useEffect(() => {
        if (forumIdParam) {
            setforumId(parseInt(forumIdParam));
        } else {
            setforumId(0);
        }
    }, []);

    return (
        <React.Fragment>
            <HelmetPages title="Doom Central - Create new thread" />
            <Sidebars loading={loading} newsItems={forumList?.newsItems} recentThreads={forumList?.recentThreads}>
                <div className='maincontent-container p-4'>
                    <Row>
                        <Col lg={8}>
                            <NewThreadOptions forumGroups={forumList?.forumGroups} forumId={forumId} setForumId={setforumId} />
                            <NewThreadTitle title={title} setTitle={setTitle} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <NewThreadBoxContainer post={post} setPost={setPost} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SubmitThreadButton forumId={forumId} post={post} title={title} />
                        </Col>
                    </Row>
                </div>
            </Sidebars>
        </React.Fragment>
    );
};