import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { IForumGroupDto } from '../../DoomCentral';
import RelativeTime from '@yaireo/relative-time';

interface ForumListProps {
    forumGroups: IForumGroupDto[]
}

export default (props: ForumListProps) => {
    const relativeTime = new RelativeTime();

    return (
        <React.Fragment>
            <div className='forum-list'>
                {props.forumGroups && props.forumGroups.map((s, idx) => (
                    <Row className='forum-container g-0' key={s.groupName}>
                        <Row xs={3} className='forum-header g-0'>
                            <Col xs={6} className='forum-group-header'>
                                {s.groupName}
                            </Col>
                            <Col xs={2} className='forum-info-header'>
                                Last Active
                            </Col>
                            <Col xs={2} className='forum-info-header'>
                                Threads
                            </Col>
                            <Col xs={2} className='forum-info-header'>
                                Posts
                            </Col>
                        </Row>
                        {s.forums.map((t, idx_) => (
                            <Row className='forum g-0 py-2' key={t.id}>
                                <Col xs={6} className='forum-main'>
                                    {t.unread && (
                                        <span className='forum-unread-posts'>
                                            •
                                            {' '}
                                        </span>
                                    )}
                                    <span className='forum-title'>
                                        <NavLink to={`/forum/${t.id}`}>
                                            {t.name}
                                        </NavLink>
                                        {' '}
                                        {t.locked && (<i className="bi bi-lock">{' '}</i>)}
                                        {t.moderated && (<i className="bi bi-shield-exclamation">{' '}</i>)}
                                        {t.hidden && (<i className="bi bi-search">{' '}</i>)}
                                    </span>
                                    <br />
                                    {t.description}
                                </Col>
                                <Col xs={2} className='forum-last-active text-wrap'>
                                    {t.lastActive && t.lastActive.postAuthorUserName && (
                                        <React.Fragment>
                                            <NavLink to={`/user/${t.lastActive.postAuthorUserName}`}>
                                                {t.lastActive.postAuthorDisplayName}
                                            </NavLink>
                                            {' '}posted
                                            <br />
                                            <NavLink to={`/thread/${t.lastActive.threadId}`}>
                                                {relativeTime.from(new Date(t.lastActive.created))}
                                            </NavLink>
                                        </React.Fragment>
                                    )}
                                    {t.lastActive && !t.lastActive.postAuthorUserName && (
                                        <React.Fragment>
                                            {t.lastActive.postAuthorDisplayName}
                                            {' '}posted
                                            <br />
                                            <NavLink to={`/thread/${t.lastActive.threadId}`}>
                                                {relativeTime.from(new Date(t.lastActive.created))}
                                            </NavLink>
                                        </React.Fragment>
                                    )}
                                </Col>
                                <Col xs={2} className='forum-thread-count'>
                                    {t.threadCount}
                                </Col>
                                <Col xs={2} className='forum-post-count'>
                                    {t.postCount}
                                </Col>
                            </Row>
                        ))}
                    </Row>  
                ))}
            </div>
        </React.Fragment>
    );
};