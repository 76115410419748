import * as React from 'react';
import { Button, Input } from 'reactstrap';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize, { defaultSchema } from "rehype-sanitize";
import { useState } from 'react';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import remarkImages from 'remark-images';
import remarkUnwrapImages from 'remark-unwrap-images';
import emoji from 'remark-emoji';
import remarkDirective from 'remark-directive';
import remarkYoutubeEmbed from '../../remarkYoutubeEmbed';
import rehypeRaw from 'rehype-raw';

interface NewPostBoxProps {
    post: string,
    setPost: React.Dispatch<React.SetStateAction<string>>
}

export default (props: NewPostBoxProps) => {
    return (
        <React.Fragment>
            <MDEditor
                value={props.post}
                onChange={props.setPost}
                previewOptions={{
                    remarkPlugins:
                        [[remarkGfm, { singleTilde: false }], remarkBreaks,
                            remarkUnwrapImages, remarkDirective, remarkImages, [emoji, { emoticon: true }],
                            remarkYoutubeEmbed],
                    rehypePlugins: [[rehypeRaw, rehypeSanitize, {
                        ...defaultSchema,
                        tagNames: [
                            ...defaultSchema.tagNames,
                            'iframe'
                        ],
                        attributes: {
                            ...defaultSchema.attributes,
                            iframe: [
                                'src', 'allow', 'allow-fullscreen', 'frameborder',
                            ]}
                    }]],
                }}
            />
        </React.Fragment>
    );
};