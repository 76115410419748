import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Alert, Button, Fade, Row } from 'reactstrap';
import { IPromotedItemDto, PromotedItemDto, PromotionsClient, SetPromotedItemsCommand } from '../../DoomCentral';
import { IErrorResponse } from '../../ErrorResponse';
import { ErrorContext } from '../../state';

interface SubmitNewPromotionsProps {
    promotions: IPromotedItemDto[]
}

export default (props: SubmitNewPromotionsProps) => {
    const error = useContext(ErrorContext);

    const [hidden, setHidden] = useState<boolean>(true);
    const [modResponse, setModResponse] = useState<number>(0);

    useEffect(() => {
        if (modResponse > 0) {
            setHidden(false);
            const timer = setTimeout(() => {
                setHidden(true);
            }, 5000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [modResponse]);

    const submitNewPromotionLineup = async () => {
        try {
            let client = new PromotionsClient();
            const command = new SetPromotedItemsCommand;
            let promotedItems: PromotedItemDto[] = [];
            props.promotions.map((s, idx) => {
                var p = new PromotedItemDto;
                p.promotedBlurb = s.promotedBlurb;
                p.promotedImageUrl = s.promotedImageUrl;
                p.promotedTitle = s.promotedTitle;
                p.promotedType = s.promotedType;
                p.resourceId = s.resourceId;
                promotedItems.push(p);
            });
            command.newPromotedItems = promotedItems;
            const response = await client.setNewPromotions(command)
                .then(response => response as number);
            setModResponse(response);
        } catch (e) {
            error.setError(e.response as IErrorResponse);
        }
    };

    return (
        <React.Fragment>
            <Row>
                <Button color="primary" onClick={e => submitNewPromotionLineup()}>Submit New Promotion Lineup</Button>
            </Row>
            <Row className='my-2'>
                <Fade in={!hidden}>
                    <Alert color="success" hidden={hidden}>
                        Success!
                        <br />
                        The new promotion order has been saved!
                        <br />
                    </Alert>
                </Fade>
            </Row>
        </React.Fragment>
    );
};
