import {
    NavItem,
    NavLink
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useAuth } from "react-oidc-context";
import { redirectToRegister } from '../api-authorization/AuthHelpers';
import React from 'react';

const NavMenuUnauthView = () => {
    const auth = useAuth();

    return (
        <React.Fragment>
            <NavItem className="ml-auto">
                <NavLink tag={Link} to={''} onClick={() => redirectToRegister()}>Register</NavLink>
            </NavItem>
            <NavItem className="ml-auto">
                <NavLink tag={Link} to={''} onClick={() => auth.signinRedirect()}>Login</NavLink>
            </NavItem>
        </React.Fragment>
    );
}

export default NavMenuUnauthView;