import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { ForumsClient, IForumThreadListingPageVm, IThreadListingPageVm } from '../../DoomCentral';
import { IErrorResponse } from '../../ErrorResponse';
import { ErrorContext } from '../../state';
import HelmetPages from '../helmet/HelmetPages';
import Sidebars from '../sidebars/Sidebars';
import ThreadList from '../threadlist/ThreadList';
import ThreadOptions from '../threadlist/ThreadOptions';
import ThreadPagination from '../threadlist/ThreadPagination';
import ForumHeader from './ForumHeader';

interface ForumThreadListingRouterProps {
    id: string
}

interface ForumThreadListingProps extends RouteComponentProps<ForumThreadListingRouterProps> {
    // Add your regular properties here
}

export default (props: ForumThreadListingProps) => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);

    const [forumThreadListing, setForumThreadListingData] = useState<IForumThreadListingPageVm>();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new ForumsClient();
                const response = await client.getThreadsByForumId(parseInt(props.match.params.id), parseInt(props.match.params.id), pageNumber, pageSize)
                    .then(response => response.toJSON() as Promise<IForumThreadListingPageVm>);
                setForumThreadListingData(response);
            } catch (e) {
                console.log(e);
                error.setError(e.response as IErrorResponse);
            }
            setLoading(false);
        };

        fetchData();

    }, [pageNumber, pageSize]);

    return (
        <React.Fragment>
            <HelmetPages title={`Doom Central - Forum - ${forumThreadListing?.forumDetails?.name ?? "Loading..."}`} />
            <Sidebars loading={loading} newsItems={forumThreadListing?.newsItems} recentThreads={forumThreadListing?.recentThreads}>
                <div className='maincontent-container p-4'>
                    <ForumHeader forumname={forumThreadListing?.forumDetails?.name} forumgroupname={forumThreadListing?.forumDetails?.groupName} />
                    <ThreadPagination
                            pageCount={forumThreadListing?.threadList?.totalPages ?? 0}
                            setPageChange={setPageNumber} />
                    <ThreadList threadList={forumThreadListing?.threadList?.threadList} />
                    <ThreadPagination
                            pageCount={forumThreadListing?.threadList?.totalPages ?? 0}
                            setPageChange={setPageNumber} />
                </div>
            </Sidebars>
        </React.Fragment>
    );
};