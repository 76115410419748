import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { Col, Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, Spinner } from 'reactstrap';
import { INotificationVm, NotificationsClient } from '../../DoomCentral';
import { IErrorResponse } from '../../ErrorResponse';
import { ErrorContext } from '../../state';
import NotificationsList from './NotificationsList';

export default () => {
    const error = useContext(ErrorContext);
    const auth = useAuth();

    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [pageNumber, setPageNumber] = useState(1);
    const [modNotification, setModNotification] = useState(0);

    const [notifications, setNotifications] = useState<INotificationVm>();

    function toggle() {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!auth.isAuthenticated)
                return;
            setLoading(true);
            try {
                let client = new NotificationsClient();
                const response = await client.getUserNotifications(pageNumber, 20)
                    .then(response => response.toJSON() as Promise<INotificationVm>);
                setNotifications(response);
            } catch (e) {
                error.setError(e.response as IErrorResponse);
            }
            setLoading(false);
        };

        fetchData();

    }, [auth.isAuthenticated, pageNumber, modNotification]);

    return (
        <React.Fragment>
            {auth.isAuthenticated && (
                <Dropdown isOpen={isOpen} toggle={toggle} nav inNavbar className='notification-nav'>
                    <DropdownToggle nav caret>
                        <i className="bi bi-bell"></i>
                    </DropdownToggle>
                    <DropdownMenu end>
                        {loading && (!notifications || notifications.postNotifications.length > 0) && (
                            <Spinner
                                className="m-5"
                                color="primary"
                            >
                                Loading...
                            </Spinner>
                        )}
                        {notifications && notifications.postNotifications.length <= 0 && (
                            <div className='text-center empty-notifications'>No notifications.</div>
                        )}
                        {notifications && notifications.postNotifications.length > 0 && (
                            <NotificationsList notifications={notifications.postNotifications} pageCount={notifications.totalPages} setPageNumber={setPageNumber} setModNotification={setModNotification} />
                        )}
                    </DropdownMenu>
                </Dropdown>
            )}
        </React.Fragment>
    );
};