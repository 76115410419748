import * as React from 'react';
import { useContext } from 'react';
import { Button } from 'reactstrap';
import { CreateNewsCommand, NewsClient } from '../../DoomCentral';
import { IErrorResponse } from '../../ErrorResponse';
import { navigateToReturnUrl } from '../../helpers';
import { ErrorContext } from '../../state';

interface SubmitNewNewsButtonProps {
    newsTitle: string,
    post: string,
    publish: boolean,
    loading: boolean,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export default (props: SubmitNewNewsButtonProps) => {
    const error = useContext(ErrorContext);

    const submitNewNews = async () => {
        props.setLoading(true);
        try {
            let client = new NewsClient();
            const command = new CreateNewsCommand;
            command.newsTitle = props.newsTitle;
            command.newsContent = props.post;
            command.publish = props.publish;
            const response = await client.create(command)
                .then(response => response as number);
            await navigateToReturnUrl(`/news/${response}`);
        } catch (e) {
            error.setError(e.response as IErrorResponse);
        }
        props.setLoading(false);
    };
    return (
        <React.Fragment>
            <div className='d-flex'>
                <div className='ms-auto'>
                    <Button color="primary" disabled={props.loading || !props.newsTitle || !props.post} onClick={e => submitNewNews()}>
                        Submit News Post
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};