import { useState, useEffect } from 'react';
import * as React from 'react';
import {
    Collapse,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useAuth } from "react-oidc-context";
import NavMenuAuthView from './NavMenuAuthView';
import NavMenuUnauthView from './NavMenuUnauthView';
import Notifications from '../notifications/Notifications';

export default () => {
    const auth = useAuth();
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        return auth.events.addAccessTokenExpiring(() => {
            auth.signinSilent();
        });
    }, [auth.events, auth.signinSilent])

    useEffect(() => {
        return auth.events.addAccessTokenExpired(() => {
            auth.signinSilent();
        });
    }, [auth.events, auth.signinSilent])

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow">
                <NavbarBrand tag={Link} to="/" aria-label="Doom Central"><img src="/images/doomcentrallogo.png" height="50" width="50" className='img-fluid dclogo' alt="Doom Central" /></NavbarBrand>
                <NavbarToggler onClick={toggle} className="mr-2" />
                <Collapse isOpen={isOpen} navbar>
                    <Nav navbar className='ms-auto'>
                        {auth.user && (
                            <NavMenuAuthView />
                        )}
                        {!auth.user && (
                            <NavMenuUnauthView />
                        )}
                        <Notifications />
                    </Nav>
                </Collapse>
            </Navbar>
        </header>
    );
};