import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, FormGroup, FormText, Input, Label } from 'reactstrap';
import { ForumsClient, IForumDto, IForumGroupsDto, UpdateForumCommand } from '../../../../DoomCentral';
import { IErrorResponse } from '../../../../ErrorResponse';
import { ErrorContext } from '../../../../state';
import PostFileSettings from '../../../postfilesettings/PostFileSettings';

interface UpdateForumProps {
    setUpdatedForum: React.Dispatch<React.SetStateAction<number>>,
    selectedForum: IForumDto,
    selectedForumGroup: IForumGroupsDto,
    loading: boolean
}

export default (props: UpdateForumProps) => {
    const error = useContext(ErrorContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [desc, setDesc] = useState<string>("");
    const [hidden, setHidden] = useState<boolean>(false);
    const [locked, setLocked] = useState<boolean>(false);
    const [moderated, setModerated] = useState<boolean>(false);

    const [tags, setTags] = useState<any[]>([]);
    const [maxFilesPerPost, setMaxFilesPerPost] = useState<number>(5);
    const [maxFileSizePerPost, setMaxFileSizePerPost] = useState<number>(2097152);

    const [updateForum, setUpdateForum] = useState<number>(0);

    useEffect(() => {
        if (props.selectedForum) {
            setName(props.selectedForum.name);
            setDesc(props.selectedForum.description);
            setHidden(props.selectedForum.hidden);
            setLocked(props.selectedForum.locked);
            setModerated(props.selectedForum.moderated);
            setMaxFileSizePerPost(props.selectedForum.maxFilesPerPost);
            setMaxFileSizePerPost(props.selectedForum.maxFileSize);
            setTags(props.selectedForum.allowedFileExtensions?.split('/') ?? []);
        }
    }, [props.selectedForum]);

    useEffect(() => {
        props.setUpdatedForum(updateForum);
    }, [updateForum]);

    const submitForum = async () => {
        setLoading(true);
        try {
            let client = new ForumsClient();
            let command = new UpdateForumCommand();
            command.id = props.selectedForum.id;
            command.name = name;
            command.description = desc;
            command.forumGroupId = props.selectedForumGroup.id;
            command.hidden = hidden;
            command.locked = locked;
            command.moderated = moderated;
            command.maxFilesPerPost = maxFilesPerPost;
            command.maxFileSize = maxFileSizePerPost;
            command.allowedFileExtensions = tags.length > 0 ? tags.join("/") : null;

            const response = await client.update(props.selectedForum.id, command)
                .then(response => response);

            setUpdateForum(updateForum + 1);
        } catch (e) {
            error.setError(e.response as IErrorResponse);
        }
        setLoading(false);
    };

    return (
        <React.Fragment>
            <FormGroup>
                <Label for="forum">
                    Update Forum
                </Label>
                <Input value={name} onChange={(e) => setName(e.target.value)} />
                <FormText>
                    Forum Name
                </FormText>
                <Input value={desc} onChange={(e) => setDesc(e.target.value)} />
                <FormText>
                    Forum Description
                </FormText>
                <FormGroup row>
                    <Label check>
                        <Input type="checkbox" checked={hidden} onChange={(e) => setHidden(e.target.checked)} />
                        {' '}
                        Hidden
                    </Label>
                </FormGroup>
                <FormGroup row>
                    <Label check>
                        <Input type="checkbox" checked={locked} onChange={(e) => setLocked(e.target.checked)} />
                        {' '}
                        Locked
                    </Label>
                </FormGroup>
                <FormGroup row>
                    <Label check>
                        <Input type="checkbox" checked={moderated} onChange={(e) => setModerated(e.target.checked)} />
                        {' '}
                        Moderated
                    </Label>
                </FormGroup>
                <PostFileSettings
                    setTags={setTags}
                    tags={tags}
                    setMaxFilesPerPost={setMaxFilesPerPost}
                    maxFilesPerPost={maxFilesPerPost}
                    setMaxFileSize={setMaxFileSizePerPost}
                    maxFileSize={maxFileSizePerPost}
                    loading={loading}
                />
                <div className='d-flex'>
                    <div className='ms-auto'>
                        <Button onClick={submitForum} disabled={!props.selectedForum || !props.selectedForumGroup || (!name && !desc) || loading} >
                            Update Forum
                        </Button>
                    </div>
                </div>
            </FormGroup>
        </React.Fragment>
    );
};