import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { formatBytes } from '../../helpers';
import { Button, Card, CardImg, CardImgOverlay, CardText, CardTitle, Input } from 'reactstrap';
import { PostFileDto } from '../../DoomCentral';

interface PostFileDisplayProps {
    postFile: PostFileDto,
    postId: number,
    removeFile: (id: number) => void
}

export default (props: PostFileDisplayProps) => {
    const getPreviewImageForFileNameString = (fileName: string, postId: number): string => {
        var approvedImageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webm', '.webp'] as string[];
        if (approvedImageExtensions.some(s => fileName.endsWith(s))) {
            return '/postfiles/' + String(postId) + '/' + fileName;
        }

        // Put other file types here.
        var zipExtensions = ['.zip', '.pk3', '.7z', '.rar'] as string[];
        if (zipExtensions.some(s => fileName.endsWith(s))) {
            return "/images/filetypes/filetypeplaceholder_zip.png"
        }

        return "/images/filetypes/filetypeplaceholder_any.png"
    };

    return (
        <React.Fragment>
            {props.postFile && (
                <React.Fragment>
                    <Card inverse>
                        <CardImg
                            alt={`Image preview for file ${props.postFile.postFileName}`}
                            src={getPreviewImageForFileNameString(props.postFile.postFileName, props.postId)}
                            style={{
                                height: 150
                            }}
                            width="100%"
                        />
                        <CardImgOverlay>
                            <CardTitle tag="h5">
                                {props.postFile.postFileName}
                            </CardTitle>
                            <CardText>
                                <small className="text-muted">
                                    {formatBytes(props.postFile.sizeBytes)}
                                </small>
                            </CardText>
                            <CardText>
                                {props.postFile.postFileDescription}
                            </CardText>
                        </CardImgOverlay>
                        <a href={getPreviewImageForFileNameString(props.postFile.postFileName, props.postId)} target="_blank" rel="noopener noreferrer" className="btn btn-primary stretched-link">Download</a>
                    </Card>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};
