import React from 'react'
import { Component } from 'react'
import { useAuth } from 'react-oidc-context'
import { Route, Redirect } from 'react-router'
import { ApplicationPaths, QueryParameterNames } from '../../configuration'
import { containsDuplicate, convertStringArray } from '../../helpers'
import AccessDenied from './AccessDenied'

interface AuthorizeRouteProps {
    exact?: boolean,
    path: string,
    component: React.FC,
    componentroles: string[] | string
};

const AuthorizeRoute = (props: AuthorizeRouteProps) => {
    const auth = useAuth();

    var link = document.createElement("a");
    link.href = props.path;
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const loginRedirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`;
    if (auth.isLoading) {
        return (<div></div>);
    } else {
        return <Route
            path={props.path}
            render={(renderprops) => {
                if (auth.user?.expired || !auth.isAuthenticated)
                {
                    return <Redirect {...renderprops} to={loginRedirectUrl} />
                }
                else if (!containsDuplicate(props.componentroles, auth.user?.profile.role as string[]))
                {
                    return <AccessDenied />
                }
                else
                {
                    return <props.component />
                }
            }} />
    }
}

export default AuthorizeRoute;