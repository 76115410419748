import * as React from 'react';

interface EventOptionsProps {
    pageSize: number,
    setPageSize: React.Dispatch<React.SetStateAction<number>>,
}

export default (props: EventOptionsProps) => {
    return (
        <React.Fragment>
            <label>
                Page Size:
                <select value={props.pageSize} onChange={e => props.setPageSize(parseInt(e.target.value, 10))}>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
            </label>
        </React.Fragment>
    );
};