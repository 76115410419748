import * as React from 'react';
import { useAuth } from 'react-oidc-context';
import { NavLink } from 'react-router-dom';
import { Button, Col, Input, InputGroup, Row } from 'reactstrap';
import { ThreadFilter } from '../../DoomCentral';
import DoomCentralRoles from '../../DoomCentralRoles';
import { containsDuplicate } from '../../helpers';

export interface ThreadOptionsProps {
    mode: ThreadFilter,
    setMode: React.Dispatch<React.SetStateAction<ThreadFilter>>
}

export default (props: ThreadOptionsProps) => {
    const auth = useAuth();

    return (
        <React.Fragment>
            <div className='thread-button-tray mb-2'>
                <Button color="primary" className='me-2' onClick={e => props.setMode(ThreadFilter.Hot)} active={props.mode === ThreadFilter.Hot}>Hot</Button>
                <Button color="primary" className='me-2' onClick={e => props.setMode(ThreadFilter.Active)} active={props.mode === ThreadFilter.Active}>Active</Button>
                <Button color="primary" className='me-2' onClick={e => props.setMode(ThreadFilter.New)} active={props.mode === ThreadFilter.New}>New</Button>
                {containsDuplicate(DoomCentralRoles.DoomCentralUser, auth.user?.profile.role as string[] ?? []) && (
                    <NavLink className='ms-auto' exact to={'/newthread'}>
                        <Button color="primary">New Thread</Button>
                    </NavLink>
                )}
            </div>
        </React.Fragment>
    );
};