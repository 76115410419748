import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import rehypeRaw from 'rehype-raw';
import remarkDirective from 'remark-directive';
import remarkImages from 'remark-images';
import remarkYoutubeEmbed from '../../remarkYoutubeEmbed';

interface MarkdownPostProps {
    content: string
}

export default (props: MarkdownPostProps) => {
    return (
        <ReactMarkdown
            remarkPlugins={[[remarkGfm, { singleTilde: false }], remarkBreaks,
                remarkDirective, remarkImages,
                remarkYoutubeEmbed]}
            rehypePlugins={[[rehypeRaw, rehypeSanitize, {
                ...defaultSchema,
                tagNames: [
                    ...defaultSchema.tagNames,
                    'iframe'
                ],
                attributes: {
                    ...defaultSchema.attributes,
                    iframe: [
                        'src', 'allow', 'allow-fullscreen', 'frameborder',
                    ]
                }
            }], ]}
        >
            {props.content}
        </ReactMarkdown>
    );
};
