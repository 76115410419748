import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { INewsListingPageVm, NewsClient } from '../../DoomCentral';
import DoomCentralRoles from '../../DoomCentralRoles';
import { IErrorResponse } from '../../ErrorResponse';
import { containsDuplicate } from '../../helpers';
import NewsPostRoles from '../../NewsPostRoles';
import { ErrorContext } from '../../state';
import HelmetPages from '../helmet/HelmetPages';
import Sidebars from '../sidebars/Sidebars';
import NewsAdmin from './NewsAdmin';
import NewsList from './NewsList';
import NewsPagination from './NewsPagination';

export enum NewsPageMode {
    AllNews = 0,
    MyNews = 1
}

 export default () => {
    const error = useContext(ErrorContext);
    const auth = useAuth();

    const [loading, setLoading] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [mode, setMode] = useState<NewsPageMode>(NewsPageMode.AllNews);

    const [newsListing, setNewsListingData] = useState<INewsListingPageVm>();

    useEffect(() => {
        if (auth.user &&
            auth.user?.profile.role as string[] &&
            containsDuplicate([
                NewsPostRoles.GlobalNewsAdministrator,
                NewsPostRoles.NewsAuthor,
                DoomCentralRoles.GlobalAdministrator
            ], auth.user?.profile.role as string[])) {
            setIsAdmin(true);
        }
        else {
            setIsAdmin(false);
        }
    }, [auth]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new NewsClient();
                const response = await client.getNewsListingPage(pageNumber, pageSize)
                    .then(response => response.toJSON() as Promise<INewsListingPageVm>);
                setNewsListingData(response);
            } catch (e) {
                error.setError(e.response as IErrorResponse);
            }
            setLoading(false);
        };

        fetchData();

    }, [pageNumber, pageSize, mode]);

    return (
        <React.Fragment>
            <HelmetPages title="Doom Central - News Listing" />
            <Sidebars loading={loading} newsItems={newsListing?.newsItems} recentThreads={newsListing?.recentThreads}>
                <div className='maincontent-container p-4'>
                    {isAdmin && (<NewsAdmin mode={mode} setMode={setMode} />)}
                    <NewsPagination pageCount={newsListing?.newsListing?.totalPages ?? 0} setPageChange={setPageNumber} />
                    <NewsList newsItems={newsListing?.newsListing?.newsList} auth={auth} />
                    <NewsPagination pageCount={newsListing?.newsListing?.totalPages ?? 0} setPageChange={setPageNumber} />
                </div>
            </Sidebars>
        </React.Fragment>
    );
};