import React, { Component, Fragment } from 'react';
import { Route } from 'react-router';
import { ApplicationPaths } from '../../configuration';
import LoginCallback from './LoginCallback';
import LoginRedirect from './LoginRedirect';
import LogoutCallback from './LogoutCallback';

const ApiAuthorizationRoutes = () => {
    return(
        <Fragment>
            <Route path={ApplicationPaths.LogOutCallback} component={LogoutCallback} />
            <Route path={ApplicationPaths.LoginCallback} component={LoginCallback} />
            <Route path={ApplicationPaths.Login} component={LoginRedirect} />
        </Fragment>
    );
}

export default ApiAuthorizationRoutes;