import * as React from 'react';
import EditPostBox from '../editpostbox/EditPostBox';

interface EditPostBoxContainerProps {
    post: string,
    setPost: React.Dispatch<React.SetStateAction<string>>
}

export default (props: EditPostBoxContainerProps) => {
    return (
        <React.Fragment>
            <div className='mt-2'>
                <EditPostBox post={props.post} setPost={props.setPost} />
            </div>
        </React.Fragment>
    );
};