import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { RouteComponentProps } from 'react-router-dom';
import { DoomCentralEventsClient, IEventVm } from '../../DoomCentral';
import { IErrorResponse } from '../../ErrorResponse';
import { UserCanPostInEventThread, UserIsEventAdmin } from '../../helpers';
import { ErrorContext } from '../../state';
import HelmetPages from '../helmet/HelmetPages';
import Reactions from '../reactions/Reactions';
import Sidebars from '../sidebars/Sidebars';
import SingleMediaContainer from '../singlemediacontainer/SingleMediaContainer';
import EventContent from './EventContent';
import EventHeader from './EventHeader';

interface EventPageRouterProps {
    id: string
}

interface EventPageProps extends RouteComponentProps<EventPageRouterProps> {
    // Add your regular properties here
}

export default (props: EventPageProps) => {
    const error = useContext(ErrorContext);

    const auth = useAuth();

    const [loading, setLoading] = useState<boolean>(false);
    const [canEdit, setCanEdit] = useState<boolean>(false);

    const [eventPageData, setEventPageData] = useState<IEventVm>();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new DoomCentralEventsClient();
                const response = await client.getEventById(parseInt(props.match.params.id))
                    .then(response => response.toJSON() as Promise<IEventVm>);
                setEventPageData(response);
            } catch (e) {
                error.setError(e.response as IErrorResponse);
            }
            setLoading(false);
        };

        fetchData();

    }, []);

    useEffect(() => {
        if (UserIsEventAdmin(auth.user, eventPageData?.eventDetails)) {
            setCanEdit(true);
        }
        else {
            setCanEdit(false);
        }
    }, [auth, eventPageData]);

    return (
        <React.Fragment>
            <HelmetPages title={`Doom Central - Event - ${eventPageData?.eventDetails?.title ?? "Loading..."}`} />
            <Sidebars loading={loading} newsItems={eventPageData?.newsItems} recentThreads={eventPageData?.recentThreads}>
                <div className='maincontent-container p-4'>
                    <SingleMediaContainer
                        loading={loading}
                        id={eventPageData?.eventDetails?.threadId ?? 0}
                        authorized={UserCanPostInEventThread(auth.user, eventPageData?.eventDetails)}
                        isAdmin={canEdit}
                        maxFilesInPost={eventPageData?.eventDetails?.maxFilesPerPost ?? 0}
                        maxFileSizeBytes={eventPageData?.eventDetails?.maxFileSize ?? 0}
                        allowedFileExtensions={eventPageData?.eventDetails?.allowedFileExtensions ?? ""}
                    >
                        <EventHeader
                            title={eventPageData?.eventDetails?.title}
                            eventOrganizer={eventPageData?.eventDetails?.eventOrganizerUserName}
                            eventOrganizerDisplayName={eventPageData?.eventDetails?.eventOrganizerDisplayName}
                            canEdit={canEdit}
                            id={eventPageData?.eventDetails?.eventId}
                            eventPublishDate={eventPageData?.eventDetails?.eventPublished}
                            loading={loading}
                        />
                        <EventContent details={eventPageData?.eventDetails} loading={loading} />
                    </SingleMediaContainer>
                </div>
            </Sidebars>
        </React.Fragment>
    );
};