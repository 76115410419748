import * as React from 'react';
import { useContext, useState } from 'react';
import { Button, Table } from 'reactstrap';
import { DeleteDoomCentralEventCommand, DoomCentralEventsClient, IEventItemDto } from '../../../DoomCentral';
import { ErrorContext } from '../../../state';

interface EventTableProps {
    events: IEventItemDto[],
    setModEvent: React.Dispatch<React.SetStateAction<number>>,
    loading: boolean
}

export default (props: EventTableProps) => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);

    const deleteSubmit = async (eventId: number) => {
        setLoading(true);
        try {
            let client = new DoomCentralEventsClient();
            let command = new DeleteDoomCentralEventCommand();
            command.eventId = eventId;
            const response = await client.delete(eventId, command)
                .then(response => response) as number;
            props.setModEvent(response);
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
        setLoading(false);
    };

    return (
        <React.Fragment>
            <Table>
                <thead>
                    <th>Id</th>
                    <th>Title</th>
                    <th>Region</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Delete</th>
                </thead>
                <tbody>
                    {props.events && props.events.map((s, idx) => (
                        <tr>
                            <td>{s.eventId}</td>
                            <td>{s.title}</td>
                            <td>{s.eventRegion}</td>
                            <td>{s.eventStartDate.toISOString()}</td>
                            <td>{s.eventEndDate.toISOString()}</td>
                            <td><Button color="danger" onClick={e => deleteSubmit(s.eventId)} disabled={loading || props.loading}>Delete Event</Button></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </React.Fragment>
    );
};