import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { EventRegion, ISidebarVm, SidebarClient } from '../../DoomCentral';
import { IErrorResponse } from '../../ErrorResponse';
import { ErrorContext } from '../../state';
import EventEditorBoxContainer from '../eventeditor/EventEditorBoxContainer';
import EventEditorEndDate from '../eventeditor/EventEditorEndDate';
import EventEditorPublish from '../eventeditor/EventEditorPublish';
import EventEditorRegionOptions from '../eventeditor/EventEditorRegionOptions';
import EventEditorStartDate from '../eventeditor/EventEditorStartDate';
import EventEditorStreamer from '../eventeditor/EventEditorStreamer';
import EventEditorTitle from '../eventeditor/EventEditorTitle';
import HelmetPages from '../helmet/HelmetPages';
import Sidebars from '../sidebars/Sidebars';
import SubmitNewEventButton from './SubmitNewEventButton';

export default () => {
    const error = useContext(ErrorContext);

    const [sidebarLoading, setSidebarLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [region, setRegion] = useState<EventRegion>(EventRegion.Global);
    const [title, setTitle] = useState<string>("");
    const [post, setPost] = useState<string>("");
    const [streamerId, setStreamerId] = useState<number>(0);
    const [sidebarData, setSidebarData] = useState<ISidebarVm>();
    const [start, setStart] = useState<string>("");
    const [end, setEnd] = useState<string>("");
    const [publish, setPublish] = useState<boolean>(false);

    // Initial load for the thread page.
    useEffect(() => {
        const fetchData = async () => {
            setSidebarLoading(true);
            try {
                let client = new SidebarClient();
                const response = await client.getSidebars()
                    .then(response => response.toJSON() as Promise<ISidebarVm>);
                setSidebarData(response);
            } catch (e) {
                error.setError(e.response as IErrorResponse);
            }
            setSidebarLoading(false);
        };

        fetchData();

    }, []);

    return (
        <React.Fragment>
            <HelmetPages title="Doom Central - New Event" />
            <Sidebars loading={sidebarLoading} newsItems={sidebarData?.newsItems} recentThreads={sidebarData?.recentThreads}>
                <div className='maincontent-container p-4'>
                    <h2>Create new event</h2>
                    <Row>
                        <Col lg={8}>
                            <EventEditorRegionOptions region={region} setRegion={setRegion} />
                            <EventEditorTitle title={title} setTitle={setTitle} />
                            <EventEditorStartDate startDate={start} setStartDate={setStart} />
                            <EventEditorEndDate endDate={end} setEndDate={setEnd} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <EventEditorBoxContainer post={post} setPost={setPost} />
                            <EventEditorStreamer streamerId={streamerId} setStreamerId={setStreamerId} />
                            <EventEditorPublish publish={publish} setPublish={setPublish} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SubmitNewEventButton
                                region={region}
                                eventName={title}
                                post={post}
                                startDate={start}
                                endDate={end}
                                publish={publish}
                                loading={loading}
                                streamerId={streamerId}
                                setLoading={setLoading}
                            />
                        </Col>
                    </Row>
                </div>
            </Sidebars>
        </React.Fragment>
    );
};