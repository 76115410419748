import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { ISidebarVm, IThreadSubscriptionDto, IUserSubscriptionVm, SidebarClient, SubscriptionClient } from '../../DoomCentral';
import { IErrorResponse } from '../../ErrorResponse';
import { ErrorContext } from '../../state';
import HelmetPages from '../helmet/HelmetPages';
import Sidebars from '../sidebars/Sidebars';
import SubscriptionList from './SubscriptionList';
import SubscriptionOptions from './SubscriptionOptions';

 export default () => {
    const error = useContext(ErrorContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [includeEventsAndNews, setIncludeEventsAndNews] = useState<boolean>(true);
    const [forumId, setForumId] = useState<number>(0);

    const [sidebarData, setSidebarData] = useState<ISidebarVm>();
    const [subData, setSubData] = useState<IThreadSubscriptionDto[]>();

    const [modSub, setModSub] = useState<number>(0);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new SidebarClient();
                const response = await client.getSidebars()
                    .then(response => response.toJSON() as Promise<ISidebarVm>);
                setSidebarData(response);
            } catch (e) {
                error.setError(e.response as IErrorResponse);
            }
            setLoading(false);
        };

        fetchData();

    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new SubscriptionClient();
                let id = forumId == 0 ? null : forumId;
                const response = await client.getSubscriptions(includeEventsAndNews, id, 1, 15)
                    .then(response => response.toJSON() as Promise<IUserSubscriptionVm>);
                setSubData(response.subscriptions);
            } catch (e) {
                error.setError(e.response as IErrorResponse);
            }
            setLoading(false);
        };

        fetchData();

    }, [includeEventsAndNews, forumId, modSub]);

    return (
        <React.Fragment>
            <HelmetPages title={"Doom Central - Manage Subscriptions"} />
            <Sidebars loading={loading} newsItems={sidebarData?.newsItems} recentThreads={sidebarData?.recentThreads}>
                <div className='maincontent-container p-4'>
                    <Row>
                        <Col>
                            <SubscriptionOptions
                                includeEventsAndNews={includeEventsAndNews}
                                setIncludeEventsAndNews={setIncludeEventsAndNews}
                                forumId={forumId}
                                setForumId={setForumId}
                            />
                        </Col>
                        <Col>
                            <SubscriptionList subscriptions={subData} setModSub={setModSub} />
                        </Col>
                    </Row>
                </div>
            </Sidebars>
        </React.Fragment>
    );
};
