import * as React from 'react';
import AuthorizeRoute from './api-authorization/AuthorizeRoute';
import { Route } from 'react-router';
import DoomCentralRoles from '../DoomCentralRoles';
import DoomCentralEventRoles from '../DoomCentralEventRoles';
import NewEvent from './newevent/NewEvent';
import EventListing from './eventlisting/EventListing';
import EventPage from './eventpage/EventPage';
import EditEvent from './editevent/EditEvent';

export default () => (
    <>
        <AuthorizeRoute path='/newevent' component={NewEvent} componentroles={[
            DoomCentralEventRoles.GlobalEventAdministrator,
            DoomCentralEventRoles.EventOrganizer,
            DoomCentralRoles.GlobalAdministrator
        ]} />
        <AuthorizeRoute exact path='/editevent' component={EditEvent} componentroles={[
            DoomCentralEventRoles.GlobalEventAdministrator,
            DoomCentralEventRoles.EventOrganizer,
            DoomCentralRoles.GlobalAdministrator
        ]} />
        <Route exact path='/events' component={EventListing} />
        <Route exact path='/event/:id' component={EventPage} />
    </>
);