import * as React from 'react';
import { useContext, useState } from 'react';
import { Button, Col, FormGroup, FormText, Input, Label } from 'reactstrap';
import { EmotesClient, FileParameter } from '../../../DoomCentral';
import { IErrorResponse } from '../../../ErrorResponse';
import { ErrorContext } from '../../../state';

interface CreateNewEmoteProps {
    setModEmote: React.Dispatch<React.SetStateAction<number>>
}

export default (props: CreateNewEmoteProps) => {
    const error = useContext(ErrorContext);

    const [emoteName, setEmoteName] = useState<string>("");
    const [emoteFile, setEmoteFile] = useState<FileParameter>();
    const [emoteCode, setEmoteCode] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const submitEmote = async () => {
        setLoading(true);
        try {
            let client = new EmotesClient();
            const response = await client.create(emoteName, emoteCode, emoteFile)
                .then(response => response) as number;
            props.setModEmote(response);
            setEmoteName("");
            setEmoteCode("");
            setEmoteFile(null);
        } catch (e) {
            error.setError(e.response as IErrorResponse);
        }
        setLoading(false);
    };

    const handleSetFile = async (evt: React.ChangeEvent<HTMLInputElement>) => {
        const upload: FileParameter = {
            data: evt.target.files[0],
            fileName: evt.target.files[0].name
        };
        setEmoteFile(upload);
    };

    return (
        <React.Fragment>
            <h2>Create Emotes</h2>
            <FormGroup row>
                <Label
                    for='emoteName'
                    sm={2}
                >
                    Emote Name
                </Label>
                <Col sm={10}>
                    <Input value={emoteName} onChange={e => setEmoteName(e.target.value)} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label
                    for='emoteCode'
                    sm={2}
                >
                    Emote Code
                </Label>
                <Col sm={10}>
                    <Input value={emoteCode} onChange={e => setEmoteCode(e.target.value)} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label
                    for='EmoteFile'
                    sm={2}
                >
                    Emote File
                </Label>
                <Col sm={10}>
                    <input type="file" id="EmoteFile" name="EmoteFile" accept="image/png, image/jpeg" onChange={handleSetFile} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col>
                    <Button disabled={!emoteName || !emoteFile || !emoteCode || loading} onClick={submitEmote}>Submit Emote</Button>
                </Col>
            </FormGroup>
        </React.Fragment>
    );
};