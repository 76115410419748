import { useState } from 'react';
import * as React from 'react';
import { Collapse, Nav, Navbar, NavbarToggler, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import Notifications from '../notifications/Notifications';

export default () => {
    const [isOpen, setIsOpen] = useState(false);

    function toggle() {
        setIsOpen(!isOpen);
    };

    return (
        <React.Fragment>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm forumnav mb-3 border-bottom">
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav navbar className='navaction'>
                        <NavItem>
                            <NavLink exact to={'/'} >
                                <i className="bi bi-house-door"></i>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to={'/threads'}>
                                Threads
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to={'/forums'}>
                                Forums
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to={'/events'}>
                                Events
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to={'/news'}>
                                News
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        </React.Fragment>
    );
};