import {
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useAuth } from "react-oidc-context";
import { redirectToProfile } from '../api-authorization/AuthHelpers';
import { containsDuplicate } from '../../helpers';
import DoomCentralRoles from '../../DoomCentralRoles';
import NewsPostRoles from '../../NewsPostRoles';
import * as React from 'react';
import ForumRoles from '../../ForumRoles';
import DoomCentralEventRoles from '../../DoomCentralEventRoles';

export default () => {
    const auth = useAuth();
    const adminMenuRoles = [
        DoomCentralRoles.GlobalAdministrator,
        DoomCentralRoles.PromotionAdministrator,
        ForumRoles.GlobalForumAdministrator,
        DoomCentralEventRoles.GlobalEventAdministrator,
        NewsPostRoles.GlobalNewsAdministrator
    ];

    return (
        <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
                Hello {auth.user?.profile.preferred_username}
            </DropdownToggle>
            <DropdownMenu end>
                <DropdownItem>
                    <NavItem className="ml-auto">
                        <NavLink tag={Link} to={''} onClick={() => redirectToProfile()}>View Profile</NavLink>
                    </NavItem>
                </DropdownItem>
                <DropdownItem>
                    <NavItem className="ml-auto">
                        <NavLink tag={Link} to={'/managesubscriptions'}>Manage Subscriptions</NavLink>
                    </NavItem>
                </DropdownItem>
                <DropdownItem divider />
                {containsDuplicate(adminMenuRoles, auth.user?.profile.role as string[]) && (
                    <React.Fragment>
                        <DropdownItem>
                            <NavItem className="ml-auto">
                                <NavLink tag={Link} to={'/admin'}>Admin Panel</NavLink>
                            </NavItem>
                        </DropdownItem>
                        <DropdownItem divider />
                    </React.Fragment>
                )}
                <DropdownItem>
                    <NavItem className="ml-auto">
                        <NavLink tag={Link} to={''} onClick={() => auth.signoutRedirect()}>Logout</NavLink>
                    </NavItem>
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};