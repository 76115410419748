import * as React from 'react';
import ReactPaginate from 'react-paginate';

interface NotificationPaginationProps {
    pageCount: number,
    setPageChange: React.Dispatch<React.SetStateAction<number>>,
}

export default (props: NotificationPaginationProps) => {
    return (
        <React.Fragment>
            <div className='d-flex justify-content-end my-2'>
                {props.pageCount > 1 && (
                    <ReactPaginate
                        previousLabel={"← Previous"}
                        nextLabel={"Next →"}
                        pageCount={props.pageCount}
                        onPageChange={p => props.setPageChange(p.selected)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        containerClassName={"pagination pagination-sm"}
                        previousLinkClassName={"page-link"}
                        nextLinkClassName={"page-link"}
                        disabledClassName={"page-item disabled"}
                        activeClassName={"page-item active"}
                        activeLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        previousClassName={'page-item'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        renderOnZeroPageCount={null}
                    />
                )}
            </div>
        </React.Fragment>
    );
};