import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { ThreadsClient, IThreadListingPageVm, ThreadFilter } from '../../DoomCentral';
import { IErrorResponse } from '../../ErrorResponse';
import { ErrorContext } from '../../state';
import HelmetPages from '../helmet/HelmetPages';
import Sidebars from '../sidebars/Sidebars';
import ThreadList from '../threadlist/ThreadList';
import ThreadOptions from '../threadlist/ThreadOptions';
import ThreadPagination from '../threadlist/ThreadPagination';

export default () => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [threadFilter, setThreadFilter] = useState<ThreadFilter>(ThreadFilter.Active);

    const [threadListing, setThreadListingData] = useState<IThreadListingPageVm>(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new ThreadsClient();
                const response = await client.getThreadList(threadFilter, pageNumber, pageSize)
                    .then(response => response.toJSON() as Promise<IThreadListingPageVm>);
                setThreadListingData(response);
            } catch (e) {
                console.log(e);
                error.setError(e.response as IErrorResponse);
            }
            setLoading(false);
        };

        fetchData();

    }, [threadFilter, pageNumber, pageSize]);

    return (
        <React.Fragment>
            <HelmetPages title="Doom Central - Thread Listing" />
            <Sidebars loading={loading} newsItems={threadListing?.newsItems} recentThreads={threadListing?.recentThreads}>
                <div className='maincontent-container p-4'>
                    <ThreadOptions mode={threadFilter} setMode={setThreadFilter} />
                    <ThreadPagination pageCount={threadListing?.threadList?.totalPages ?? 0}
                            setPageChange={setPageNumber} />
                    <ThreadList threadList={threadListing?.threadList?.threadList} />
                    <ThreadPagination
                            pageCount={threadListing?.threadList?.totalPages ?? 0}
                            setPageChange={setPageNumber} />
                </div>
            </Sidebars>
        </React.Fragment>
    );
};