import * as React from 'react';
import AuthorizeRoute from './api-authorization/AuthorizeRoute';
import { Route } from 'react-router';
import DoomCentralRoles from '../DoomCentralRoles';
import ManageSubscriptions from './managesubscriptions/ManageSubscriptions';
import ProfilePage from './profilepage/ProfilePage';

export default () => (
    <React.Fragment>
        <AuthorizeRoute path='/managesubscriptions' component={ManageSubscriptions} componentroles={DoomCentralRoles.DoomCentralUser} />
        <Route exact path='/user/:userName' component={ProfilePage} />
    </React.Fragment>
);