import * as React from 'react';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label, Table } from 'reactstrap';
import { IPromotableItemDto } from '../../DoomCentral';

interface PromotionInfoProps {
    selectedResource: IPromotableItemDto,
    title: string,
    setTitle: React.Dispatch<React.SetStateAction<string>>,
    blurb: string,
    setBlurb: React.Dispatch<React.SetStateAction<string>>,
    imageUrl: string,
    setImageUrl: React.Dispatch<React.SetStateAction<string>>
}

export default (props: PromotionInfoProps) => {
    return (
        <React.Fragment>
            <Form>
                <FormGroup row>
                    <Label for="title">
                        Title
                    </Label>
                    <Input name="title" value={props.title} onChange={e => props.setTitle(e.target.value)} />
                    <Button className='my-2' disabled={!props.selectedResource} onClick={(e) => props.setTitle(props.selectedResource.title)}>Use resource title</Button>
                </FormGroup>
                <FormGroup row>
                    <Label for="blurb">
                        Blurb
                    </Label>
                    <Input name="blurb" value={props.blurb} onChange={e => props.setBlurb(e.target.value)} />
                    <Button className='my-2' disabled={!props.selectedResource} onClick={(e) => props.setBlurb(props.selectedResource.sampleContent)}>Use resource blurb</Button>
                </FormGroup>
                <FormGroup row>
                    <Label for="image">
                        Image Url
                    </Label>
                    <Input name="image" value={props.imageUrl} onChange={e => props.setImageUrl(e.target.value)} />
                </FormGroup>
            </Form>
        </React.Fragment>
    );
};