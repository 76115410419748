import { AxiosRequestConfig, RawAxiosRequestHeaders } from "axios";
import { User } from "oidc-client-ts"
import { authority_url, client_id } from './configuration'

export class ApiClientBase {
    protected getUser() {
        const oidcStorage = localStorage.getItem(`oidc.user:${authority_url}:${client_id}`)
        if (!oidcStorage) {
            return null;
        }

        return User.fromStorageString(oidcStorage);
    }

    protected transformOptions(options: AxiosRequestConfig<any>): Promise<AxiosRequestConfig<any>> {
        // Attach bearer token to request.
        const user = this.getUser();
        const token = user?.access_token;
        options.headers = { ...options.headers as RawAxiosRequestHeaders, authorization: `Bearer ${token}` };
        return Promise.resolve(options);
    }
}
