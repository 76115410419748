import * as React from 'react';
import Sidebars from '../sidebars/Sidebars';
import Promoted from '../promoted/Promoted';
import { useContext, useEffect, useState } from 'react';
import { ErrorContext } from '../../state';
import { HomeClient, IHomePageVm } from '../../DoomCentral';
import { IErrorResponse } from '../../ErrorResponse';
import { useAuth } from 'react-oidc-context';
import { containsDuplicate } from '../../helpers';
import DoomCentralRoles from '../../DoomCentralRoles';
import PromotionAdmin from './PromotionAdmin';
import EventList from '../eventlisting/EventList';
import HelmetPages from '../helmet/HelmetPages';

export default () => {
    const error = useContext(ErrorContext);
    const auth = useAuth();
    const [loading, setLoading] = useState<boolean>(false);

    const [homepageData, setHomepageData] = useState<IHomePageVm>();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new HomeClient();
                const response = await client.getHomePage()
                    .then(response => response.toJSON() as Promise<IHomePageVm>);
                setHomepageData(response);
            } catch (e) {
                error.setError(e.response as IErrorResponse);
            }
            setLoading(false);
        };

        fetchData();

    }, []);

    return (
        <React.Fragment>
            <HelmetPages title={"Doom Central - Home"} />
            <Sidebars recentThreads={homepageData?.recentThreads} newsItems={homepageData?.newsItems} loading={loading}>
                <div className='maincontent-container p-4'>
                    {
                        auth.user &&
                        auth.user?.profile.role as string[] &&
                        containsDuplicate([
                            DoomCentralRoles.PromotionAdministrator,
                            DoomCentralRoles.GlobalAdministrator
                        ], auth.user?.profile.role as string[]) &&
                        (<PromotionAdmin />)
                    }

                    <Promoted loading={loading} promotedItems={homepageData?.promotedItems} />
                    <EventList auth={auth} events={homepageData?.eventItems} />
                </div>
            </Sidebars>
        </React.Fragment>
    );
};