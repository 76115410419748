import * as React from 'react';
import { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { IPromotableItemDto, IPromotedItemDto } from '../../DoomCentral';
import AddToCurrentPromotions from './AddToCurrentPromotions';
import PromotionInfo from './PromotionInfo';
import SearchForResources from './SearchForResources';
import SelectResource from './SelectResource';

interface SearchAddPromotionsProps {
    promotions: IPromotedItemDto[],
    setPromotions: React.Dispatch<React.SetStateAction<IPromotedItemDto[]>>,
    modPromotion: number,
    setModPromotion: React.Dispatch<React.SetStateAction<number>>
}

export default (props: SearchAddPromotionsProps) => {
    const [promotableItems, setPromotableItems] = useState<IPromotableItemDto[]>();
    const [selectedResource, setSelectedResource] = useState<IPromotableItemDto>();
    const [title, setTitle] = useState<string>("");
    const [blurb, setBlurb] = useState<string>("");
    const [imageUrl, setImageUrl] = useState<string>("");

    useEffect(() => {
        setTitle("");
        setBlurb("");
        setImageUrl("");
        setSelectedResource(null);
        setPromotableItems([]);
    }, [props.modPromotion]);

    return (
        <React.Fragment>
            <SearchForResources setResources={setPromotableItems} modPromotion={props.modPromotion} />
            <Row>
                <Col>
                    <SelectResource resources={promotableItems} selectedResource={selectedResource} setSelectedResource={setSelectedResource} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <PromotionInfo selectedResource={selectedResource} title={title} setTitle={setTitle} blurb={blurb} setBlurb={setBlurb} imageUrl={imageUrl} setImageUrl={setImageUrl} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <AddToCurrentPromotions promotions={props.promotions} setPromotions={props.setPromotions} selectedResource={selectedResource} title={title} blurb={blurb} imageUrl={imageUrl} setModPromotion={props.setModPromotion} />
                </Col>
            </Row>
        </React.Fragment>
    );
};
