import { Route } from 'react-router';
import { BrowserRouter, BrowserRouter as Router } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import Layout from './components/Layout';
import Home from './components/home/Home';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import ForumRoutes from './components/ForumRoutes';
import AdminRoutes from './components/AdminRoutes';
import NewsRoutes from './components/NewsRoutes';
import EventRoutes from './components/EventRoutes';
import { ErrorContext } from './state';
import { GoogleAnalyticsClient } from './DoomCentral';
import { IErrorResponse } from './ErrorResponse';
import DoomCentralRoutes from './components/DoomCentralRoutes';
import PromotionRoutes from './components/PromotionRoutes';


export default () => {
    const error = useContext(ErrorContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [initialized, setInitialized] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new GoogleAnalyticsClient();
                const response = await client.getGoogleAnalyticsId()
                    .then(response => response as string);
                ReactGA.initialize(response);
                setInitialized(true);
            } catch (e) {
                error.setError(e.response as IErrorResponse);
            }
            setLoading(false);
        };

        fetchData();

    }, []);

    useEffect(() => {
        if (initialized) {
            ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
        }
    }, [initialized, location]);

    return (
        <Router>
            <Layout>
                <ApiAuthorizationRoutes />
                <AdminRoutes />
                <DoomCentralRoutes />
                <PromotionRoutes />
                <ForumRoutes />
                <NewsRoutes />
                <EventRoutes />
                <Route exact path='/' component={Home} />
            </Layout>
        </Router>
    );
};