import * as React from 'react';
import { IEventDetailDto } from '../../DoomCentral';
import EventDescription from './EventDescription';
import EventInfo from './EventInfo';

interface EventContentProps {
    details: IEventDetailDto,
    loading: boolean
}

export default (props: EventContentProps) => {
    return (
        <React.Fragment>
            <div className='event-container'>
                <EventInfo
                    eventStartDate={props.details?.eventStartDate}
                    eventEndDate={props.details?.eventEndDate}
                    duration={props.details?.eventDuration}
                    streamer={props.details?.eventStreamer}
                    streamerPic={props.details?.eventStreamProfilePicName}
                    region={props.details?.eventRegion}
                    loading={props.loading}
                />
                <EventDescription postContent={props.details?.eventPostContent} />
            </div>
        </React.Fragment>
    );
};