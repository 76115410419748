import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { DoomCentralEventsClient, IEventsPageVm } from '../../DoomCentral';
import DoomCentralRoles from '../../DoomCentralRoles';
import { containsDuplicate } from '../../helpers';
import { ErrorContext } from '../../state';
import DoomCentralEventRoles from '../../DoomCentralEventRoles';
import Sidebars from '../sidebars/Sidebars';
import EventAdmin from './EventAdmin';
import EventList from './EventList';
import EventOptions from './EventOptions';
import EventPagination from './EventPagination';
import { IErrorResponse } from '../../ErrorResponse';
import HelmetPages from '../helmet/HelmetPages';

export enum EventsPageMode {
    AllEvents = 0,
    MyEvents = 1
}

export default () => {
    const error = useContext(ErrorContext);
    const auth = useAuth();
    const [loading, setLoading] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [upcomingEventsPageNumber, setUpcomingEventsPageNumber] = useState<number>(1);
    const [upcomingEventsPageSize, setUpcomingEventsPageSize] = useState<number>(10);
    const [pastEventsPageNumber, setPastEventPageNumber] = useState<number>(1);
    const [pastEventsPageNumberPageSize, setPastEventsPageNumberPageSize] = useState<number>(10);
    const [mode, setMode] = useState<EventsPageMode>(EventsPageMode.AllEvents);

    const [eventListingData, setEventListingData] = useState<IEventsPageVm>();

    useEffect(() => {
        if (auth.user &&
            auth.user?.profile.role as string[] &&
            containsDuplicate([
                DoomCentralEventRoles.GlobalEventAdministrator,
                DoomCentralEventRoles.EventOrganizer,
                DoomCentralRoles.GlobalAdministrator
            ], auth.user?.profile.role as string[])) {
            setIsAdmin(true);
        }
        else {
            setIsAdmin(false);
        }
    }, [auth]);

    useEffect(() => {
        if (mode === EventsPageMode.AllEvents) {
            const fetchData = async () => {
                setLoading(true);
                try {
                    let client = new DoomCentralEventsClient();
                    const response = await client.getEvents(upcomingEventsPageNumber, upcomingEventsPageSize, pastEventsPageNumber, pastEventsPageNumberPageSize)
                        .then(response => response.toJSON() as Promise<IEventsPageVm>);
                    setEventListingData(response);
                } catch (e) {
                    error.setError(e.response as IErrorResponse);
                }
                setLoading(false);
            };

            fetchData();
        }
        else if (mode === EventsPageMode.MyEvents) {
            const fetchData = async () => {
                setLoading(true);
                try {
                    let client = new DoomCentralEventsClient();
                    const response = await client.getEvents(upcomingEventsPageNumber, upcomingEventsPageSize, pastEventsPageNumber, pastEventsPageNumberPageSize)
                        .then(response => response.toJSON() as Promise<IEventsPageVm>);
                    setEventListingData(response);
                } catch (e) {
                    error.setError(e.response as IErrorResponse);
                }
                setLoading(false);
            };

            fetchData();
        }

    }, [upcomingEventsPageNumber, upcomingEventsPageSize, pastEventsPageNumber, pastEventsPageNumberPageSize, mode]);

    return (
        <React.Fragment>
            <HelmetPages title="Doom Central - Event Listing" />
            <Sidebars loading={loading} newsItems={eventListingData?.newsItems} recentThreads={eventListingData?.recentThreads}>
                <div className='maincontent-container p-4'>
                    {isAdmin && (<EventAdmin mode={mode} setMode={setMode} />)}
                    {eventListingData?.liveEventItems?.eventList.length > 0 && (
                        <>
                            <h2>Events Live Now!</h2>
                            <EventList events={eventListingData?.liveEventItems?.eventList} auth={auth} />
                        </>
                    )}
                    <h2>Upcoming Events</h2>
                    <EventOptions pageSize={upcomingEventsPageSize} setPageSize={setUpcomingEventsPageSize} />
                    <EventPagination pageCount={eventListingData?.upcomingEventItems?.totalPages ?? 0} setPageChange={setUpcomingEventsPageNumber} />
                    <EventList events={eventListingData?.upcomingEventItems?.eventList} auth={auth} />
                    <h2>Past Events</h2>
                    <EventOptions pageSize={pastEventsPageNumberPageSize} setPageSize={setPastEventsPageNumberPageSize} />
                    <EventPagination pageCount={eventListingData?.pastEventItems?.totalPages ?? 0} setPageChange={setPastEventPageNumber} />
                    <EventList events={eventListingData?.pastEventItems?.eventList} auth={auth} />
                </div>
            </Sidebars>
        </React.Fragment>
    );
};