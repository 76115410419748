import * as React from 'react';
import { FormGroup, FormText, Input, Label } from 'reactstrap';

interface NewThreadTitleProps {
    title: string,
    setTitle: React.Dispatch<React.SetStateAction<string>>
}

export default (props: NewThreadTitleProps) => {
    return (
        <React.Fragment>
            <FormGroup>
                <Label for="title">
                    Title
                </Label>
                <Input value={props.title} onChange={e => props.setTitle(e.target.value)} />
                <FormText>
                    Title cannot be changed after the initial post is made.
                </FormText>
            </FormGroup>
        </React.Fragment>
    );
};