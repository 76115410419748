import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { NodeApi, Tree } from 'react-arborist';
import { Button, FormGroup, FormText, Input, InputGroup, InputGroupText, Label, ListGroup, ListGroupItem } from 'reactstrap';
import { ForumTreeNode } from './ForumPermissionTreeNode';
import { IForumTreeObject } from './ForumPermissionTreeObject';

interface SelectForumAndGroupProps {
    loading: boolean,
    forumTree: IForumTreeObject[],
    setSelectedTreeItem: React.Dispatch<React.SetStateAction<IForumTreeObject>>,
}

const selectTreeItem = (nodes: NodeApi<IForumTreeObject>[]): IForumTreeObject  => {
    return nodes[0]?.data ?? null;
};

export default (props: SelectForumAndGroupProps) => {
    return (
        <React.Fragment>
            <Tree
                data={props.forumTree}
                openByDefault={true}
                width={400}
                height={400}
                indent={24}
                rowHeight={36}
                overscanCount={1}
                paddingTop={30}
                paddingBottom={10}
                padding={25 /* sets both */}
                disableMultiSelection={true}
                disableDrag={true}
                disableDrop={true}
                idAccessor={(d) => d.id.toString()}
                childrenAccessor={(d) => d.children}
                onSelect={s => props.setSelectedTreeItem(selectTreeItem(s))}
            >
                {ForumTreeNode}
            </Tree>
        </React.Fragment>
    );
};