import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, Label } from 'reactstrap';
import { DeleteReactionCommand, IEmotesDto, IReactionsDto, IReactionsListVm, ReactionsClient } from '../../../DoomCentral';
import { IErrorResponse } from '../../../ErrorResponse';
import { ErrorContext } from '../../../state';

interface DeleteReactionProps {
    modReaction: number,
    setModReaction: React.Dispatch<React.SetStateAction<number>>
}

export default (props: DeleteReactionProps) => {
    const error = useContext(ErrorContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [reactions, setReactions] = useState<IReactionsDto[]>(null);
    const [selectedReaction, setSelectedReaction] = useState<IReactionsDto>(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new ReactionsClient();
                const response = await client.get()
                    .then(response => response.toJSON() as Promise<IReactionsListVm>);
                setReactions(response.reactions);
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();

    }, [props.modReaction]);

    const submitDeleteReaction = async () => {
        setLoading(true);
        try {
            let client = new ReactionsClient();
            let command = new DeleteReactionCommand();
            command.reactionId = selectedReaction.id;
            const response = await client.delete(selectedReaction.id, command)
                .then(response => response as number);
            props.setModReaction(response);
        } catch (e) {
            error.setError(e.response as IErrorResponse);
        }
        setLoading(false);
    };

    return (
        <React.Fragment>
            <h2>Delete Reaction</h2>
            <FormGroup row>
                <Label>
                    Reactions:
                </Label>
                <Col sm={10}>
                    <Input type="select" value={selectedReaction?.id ?? 0} onChange={e => setSelectedReaction(reactions.find(f => f.id === parseInt(e.target.value, 10)) ?? reactions[0])} disabled={loading || !reactions || reactions.length <= 0}>
                        {reactions && reactions.map((s, idx) => (
                            <option value={s.id} key={s.id}>{s.reactName}</option>    
                        ))}
                    </Input>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col>
                    {selectedReaction && (<img src={'/images/reactions/' + selectedReaction.reactFileName} />)}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col>
                    <Button disabled={!reactions || reactions.length <= 0 || !selectedReaction || loading} onClick={submitDeleteReaction} color="danger">Delete Reaction</Button>
                </Col>
            </FormGroup>
        </React.Fragment>
    );
};