import * as React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';

interface EventEditorEndDateProps {
    endDate: string
    setEndDate: React.Dispatch<React.SetStateAction<string>>,
}

export default (props: EventEditorEndDateProps) => {
    return (
        <React.Fragment>
            <FormGroup row>
                <Label for="EndDate" sm={2}>
                    End Date
                </Label>
                <Col sm={10}>
                    <input type="datetime-local" value={props.endDate} onChange={(e) => props.setEndDate(e.target.value)} />
                </Col>
            </FormGroup>
        </React.Fragment>
    );
};