import * as React from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import { EventRegion } from '../../DoomCentral';

interface EventEditorRegionOptionsProps {
    region: EventRegion,
    setRegion: React.Dispatch<React.SetStateAction<EventRegion>>
}

export default (props: EventEditorRegionOptionsProps) => {
    return (
        <React.Fragment>
            <FormGroup row>
                <Label for="region" sm={2}>
                    Region
                </Label>
                <Col sm={10}>
                    <Input type='select' name="region" id="region" value={EventRegion[props.region]} onChange={(e) => props.setRegion(EventRegion[e.target.value])}>
                        {(Object.keys(EventRegion).filter((v) =>
                            !isNaN(Number(v)),
                        ) as (keyof typeof EventRegion)[]
                        ).map((key, index) => (
                            <option key={key} value={EventRegion[key]}>
                                {EventRegion[key]}
                            </option>
                        ))}
                    </Input>
                </Col>
            </FormGroup>
        </React.Fragment>
    );
};