import * as React from 'react';
import AuthorizeRoute from './api-authorization/AuthorizeRoute';
import { Route } from 'react-router';
import NewNews from './newnews/NewNews';
import NewsPostRoles from '../NewsPostRoles';
import DoomCentralRoles from '../DoomCentralRoles';
import NewsListing from './newslisting/NewsListing';
import NewsPage from './newspage/NewsPage';
import EditNews from './editnews/EditNews';

export default () => (
    <React.Fragment>
        <AuthorizeRoute path='/newnews' component={NewNews} componentroles={[
            NewsPostRoles.GlobalNewsAdministrator,
            NewsPostRoles.NewsAuthor,
            DoomCentralRoles.GlobalAdministrator
        ]} />
        <AuthorizeRoute path='/editnews' component={EditNews} componentroles={[
            NewsPostRoles.GlobalNewsAdministrator,
            NewsPostRoles.NewsAuthor,
            DoomCentralRoles.GlobalAdministrator
        ]} />
        <Route exact path='/news' component={NewsListing} />
        <Route exact path='/news/:id' component={NewsPage} />
    </React.Fragment>
);