import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Alert, Col, Fade, Row } from 'reactstrap';
import { IUserDto, IUserSummaryVm, UsersClient } from '../../../DoomCentral';
import { IErrorResponse } from '../../../ErrorResponse';
import { ErrorContext } from '../../../state';
import UserBadges from './useractionpanel/UserBadges';
import UserBan from './useractionpanel/UserBan';
import UserRoles from './useractionpanel/UserRoles';

interface UserTableProps {
    selectedUser: IUserDto,
    modUser: string,
    setModUser: React.Dispatch<React.SetStateAction<string>>,
    hidden: boolean,
    setHidden: React.Dispatch<React.SetStateAction<boolean>>
}

export default (props: UserTableProps) => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [userSummary, setUserSummary] = useState<IUserSummaryVm>(null);

    useEffect(() => {
        const fetchData = async () => {
            if (props.selectedUser) {
                setLoading(true);
                try {
                    let client = new UsersClient();
                    const response = await client.getSummaryForUser(props.selectedUser.id)
                        .then(response => response.toJSON() as Promise<IUserSummaryVm>);
                    setUserSummary(response);
                } catch (e) {
                    error.setError(e.response as IErrorResponse);
                }
                setLoading(false);
            }
        };

        fetchData();

    }, [props.modUser, props.selectedUser]);

    return (
        <React.Fragment>
            {props.selectedUser && (
                <Row className='my-2 py-2 border'>
                    <Col>
                        <UserRoles
                            selectedUser={props.selectedUser}
                            setModUser={props.setModUser}
                            userRoles={userSummary?.userRoles}
                            setHidden={props.setHidden}
                        />
                    </Col>
                    <Col>
                        <UserBan selectedUser={props.selectedUser} setModUser={props.setModUser} setHidden={props.setHidden} />
                    </Col>
                    <hr className='mt-4' />
                    <Row>
                        <UserBadges
                            selectedUser={props.selectedUser}
                            setModUser={props.setModUser}
                            userBadges={userSummary?.userBadges}
                            availableBadges={userSummary?.availableBadges}
                            setHidden={props.setHidden}
                        />
                    </Row>
                </Row>
            )}
            <Row>
                <Fade in={!props.hidden}>
                    <Alert color="success" hidden={props.hidden}>
                        Changes have been saved successfully!
                    </Alert>
                </Fade>
            </Row>
        </React.Fragment>
    );
};