import * as React from 'react';
import styled from 'styled-components';
import { useState, useCallback, useEffect } from 'react';
import { FileRejection } from 'react-dropzone';
import PostFileRejection from './PostFileRejection';

interface PostFileRejectionsProps {
    fileRejects: FileRejection[],
}

export default (props: PostFileRejectionsProps) => {
    return (
        <React.Fragment>
            {
                props.fileRejects &&
                props.fileRejects.length > 0 && (
                    <PostFileRejectionContainer>
                        <p className="text-danger">The following files could not be uploaded: </p>
                        {
                            props.fileRejects.map((s, idx) => (
                                <PostFileRejection rejection={s} />
                            ))
                        }
                    </PostFileRejectionContainer>
                )}
        </React.Fragment>
    );
};

const PostFileRejectionContainer = styled.div`
    padding: 7px 10px;
    margin: 0;
    font-size: 13px;
    line-height: 16px;
    color: var(--bs-secondary-text-emphasis);
    background-color: var(--bs-secondary-bg);
    border: 1px solid var(--bs-secondary-color);
    border-top: 0;
    border-top-color: currentcolor;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
`;
