import { visit } from 'unist-util-visit'

// This plugin is an example to turn `::youtube` into iframes.
/** @type {import('unified').Plugin<[], import('mdast').Root>} */
export default () => {
    return (tree, file) => {
        visit(tree, (node) => {
            if (
                node.type === 'textDirective' ||
                node.type === 'leafDirective' ||
                node.type === 'containerDirective'
            ) {
                if (node.name !== 'youtube') return

                const data = node.data || (node.data = {})
                const attributes = node.attributes || {}
                const id = attributes.id

                if (node.type === 'textDirective') console.log('Text directives for `youtube` not supported')
                if (!id) console.log('Missing video id')

                data.hName = 'iframe'
                data.hProperties = {
                    src: 'https://www.youtube.com/embed/' + id,
                    frameBorder: 0,
                    allow: 'picture-in-picture',
                    allowFullScreen: true,
                    width: '350px',
                    height: '300px'
                }
            }
        })
    }
};