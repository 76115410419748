import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { INewsListingPageVm, INewsPaginatedListVm, NewsClient } from '../../../DoomCentral';
import { ErrorContext } from '../../../state';
import HelmetPages from '../../helmet/HelmetPages';
import AdminPanel from '../AdminPanel';
import NewsOptions from './NewsOptions';
import NewsPagination from './NewsPagination';
import NewsTable from './NewsTable';

export default () => {
    const error = useContext(ErrorContext);

    const [modNews, setModNews] = useState<number>(0);
    const [news, setNews] = useState<INewsPaginatedListVm>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(40);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new NewsClient();
                const response = await client.getNewsListingPage(pageNumber, pageSize)
                    .then(response => response.toJSON() as Promise<INewsListingPageVm>);
                setNews(response.newsListing);
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();

    }, [modNews]);

    return (
        <React.Fragment>
            <HelmetPages title={"Doom Central - Manage News"} />
            <AdminPanel>
                <h1>Manage News</h1>
                <NewsPagination pageCount={news?.totalPages ?? 0} setPageChange={setPageNumber} />
                <NewsOptions pageSize={pageSize} setPageSize={setPageSize} />
                <NewsTable news={news?.newsList} setModNews={setModNews} loading={loading} />
                <NewsPagination pageCount={news?.totalPages ?? 0} setPageChange={setPageNumber} />
            </AdminPanel>
        </React.Fragment>
    );
};