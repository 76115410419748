import * as React from 'react';
import { useContext, useState } from 'react';
import { Button } from 'reactstrap';
import { CreateForumThreadCommand, ThreadsClient } from '../../DoomCentral';
import { IErrorResponse } from '../../ErrorResponse';
import { navigateToReturnUrl } from '../../helpers';
import { ErrorContext } from '../../state';

interface SubmitThreadButtonProps {
    forumId: number,
    post: string,
    title: string
}

export default (props: SubmitThreadButtonProps) => {
    const error = useContext(ErrorContext);

    const [loading, setLoading] = useState<boolean>(false);

    const submitPost = async () => {
        setLoading(true);
        try {
            let client = new ThreadsClient();
            let command = new CreateForumThreadCommand();
            command.forumId = props.forumId;
            command.originalPost = props.post;
            command.title = props.title;

            const response = await client.create(command)
                .then(response => response);
            await navigateToReturnUrl(`/thread/${response}`);
        } catch (e) {
            error.setError(e.response as IErrorResponse);
        }
        setLoading(false);
    };

    return (
        <React.Fragment>
            <div className='d-flex'>
                <div className='ms-auto'>
                    <Button disabled={props.forumId <= 0 || !props.post || !props.title || loading} onClick={submitPost}>
                        Submit Thread
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};