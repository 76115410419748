import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { ForumsClient, IForumDto, IForumListVm } from '../../DoomCentral';
import { ErrorContext } from '../../state';

interface SubscriptionOptionsProps {
    includeEventsAndNews: boolean,
    setIncludeEventsAndNews: React.Dispatch<React.SetStateAction<boolean>>,
    forumId: number,
    setForumId: React.Dispatch<React.SetStateAction<number>>,
}

export default (props: SubscriptionOptionsProps) => {
    const error = useContext(ErrorContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [forums, setForums] = useState<IForumDto[]>();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new ForumsClient();
                const response = await client.getForums()
                    .then(response => response.toJSON() as Promise<IForumListVm>);
                setForums(response.forums);
            } catch (e) {
                error.setError(e.response);
            }
            setLoading(false);
        };

        fetchData();

    }, []);

    return (
        <React.Fragment>
            <Form>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="forumSelect">
                                Forum
                            </Label>
                            <Input
                                id="forumSelect"
                                name="forum"
                                type="select"
                                onSelect={e => props.setForumId(parseInt(e.currentTarget.value))}
                            >
                                <option id='0'>All Forums</option>
                                {forums && forums.length > 0 && forums.map((s, idx) => (
                                    <option id={s.id.toString()} key={s.id}>{s.name}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup check>
                    <Input
                        id="includeEventsandNews"
                        name="events"
                        type="checkbox"
                        onChange={e => props.setIncludeEventsAndNews(e.currentTarget.checked)}
                        checked={props.includeEventsAndNews}
                        value={props.forumId}
                    />
                    <Label
                        check
                        for="includeEventsandNews"
                    >
                        Include Events & News Threads
                    </Label>
                </FormGroup>
            </Form>
        </React.Fragment>
    );
};
