import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { ForumsClient, IForumListingVm } from '../../DoomCentral';
import { IErrorResponse } from '../../ErrorResponse';
import { ErrorContext } from '../../state';
import HelmetPages from '../helmet/HelmetPages';
import Sidebars from '../sidebars/Sidebars';
import ForumList from './ForumList';
import ForumOptions from './ForumOptions';

export default () => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);

    const [forumListingData, setForumListingData] = useState<IForumListingVm>();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new ForumsClient();
                const response = await client.getForumListing()
                    .then(response => response.toJSON() as Promise<IForumListingVm>);
                setForumListingData(response);
            } catch (e) {
                error.setError(e.response as IErrorResponse);
            }
            setLoading(false);
        };

        fetchData();

    }, []);

    return (
        <React.Fragment>
            <HelmetPages title="Doom Central - Forum Listing" />
            <Sidebars loading={loading} newsItems={forumListingData?.newsItems} recentThreads={forumListingData?.recentThreads}>
                <div className='maincontent-container p-4'>
                    <ForumList forumGroups={forumListingData?.forumGroups} />
                </div>
            </Sidebars>
        </React.Fragment>
    );
};