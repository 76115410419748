import * as React from 'react';

const Footer = () => {
    return (
        <React.Fragment>
            <footer className='pt-4 my-md-5 pt-md-5 border-top'>
                <div className="row">
                    <div className="col-12 col-md">
                        <small className="d-block mb-3 text-muted">© 2022 Doom Central</small>
                    </div>
                    <div className="col-6 col-md">
                        <h5>About</h5>
                        <p className="text-small">
                            Doom Central is your source for classic Doom entertainment!
                        </p>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    );
};

export default Footer;