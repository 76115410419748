import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, Label } from 'reactstrap';
import { BadgesClient, DeleteBadgeCommand, IBadgeListVm, IBadgesDto, IEmotesDto, IReactionsDto, IReactionsListVm, ReactionsClient } from '../../../DoomCentral';
import { IErrorResponse } from '../../../ErrorResponse';
import { ErrorContext } from '../../../state';

interface DeleteBadgeProps {
    modBadge: number,
    setModBadge: React.Dispatch<React.SetStateAction<number>>
}

export default (props: DeleteBadgeProps) => {
    const error = useContext(ErrorContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [badges, setBadges] = useState<IBadgesDto[]>(null);
    const [selectedBadge, setSelectedBadge] = useState<IBadgesDto>(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new BadgesClient();
                const response = await client.get()
                    .then(response => response.toJSON() as Promise<IBadgeListVm>);
                setBadges(response.badges);
            } catch (e) {
                error.setError(e.response as IErrorResponse);
            }
            setLoading(false);
        };

        fetchData();

    }, [props.modBadge]);

    useEffect(() => {
        if (badges && badges.length > 0) {
            setSelectedBadge(badges[0]);
        }
    }, [badges]);

    const submitDeleteBadge = async () => {
        setLoading(true);
        try {
            let client = new BadgesClient();
            let command = new DeleteBadgeCommand();
            command.badgeId = selectedBadge.id;
            const response = await client.delete(selectedBadge.id, command)
                .then(response => response as number);
            props.setModBadge(response);
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
        setLoading(false);
    };

    return (
        <React.Fragment>
            <h2>Delete Badges</h2>
            <FormGroup row>
                <Label for='badgeSelect'>
                    Badges
                </Label>
                <Col sm={10}>
                    <Input
                        type='select'
                        id='badgeSelect'
                        name='badgeSelect'
                        onChange={e => setSelectedBadge(badges.find(f => f.id === parseInt(e.target.value, 10)) ?? badges[0])}
                        disabled={loading || !badges || badges.length <= 0}
                    >
                    {badges && badges.map((s, idx) => (
                        <option value={s.id} key={s.id}>{s.badgeName}</option>
                    ))}
                    </Input>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col>
                    {selectedBadge && (<img src={'/images/badges/' + selectedBadge.badgeFileName} />)}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col>
                    <Button disabled={!badges || !selectedBadge || loading} onClick={submitDeleteBadge} color="danger">Delete Badge</Button>
                </Col>
            </FormGroup>
        </React.Fragment>
    );
};