import * as Moment from 'moment';
import 'moment/min/locales';
import { User } from 'oidc-client-ts';
import { IEventDetailDto, INewsPostDetailDto, IPostItemDto, IPromotedItemDto, IThreadDetailDto, IThreadListItemDto, PostNotificationType, PromotedType } from './DoomCentral';
import DoomCentralEventRoles from './DoomCentralEventRoles';
import DoomCentralRoles from './DoomCentralRoles';
import ForumRoles from './ForumRoles';
import NewsPostRoles from './NewsPostRoles';
import RelativeTime from '@yaireo/relative-time';

export const FormatShortTimeString = (iso8601duration: string) => {
    if (Moment.duration(iso8601duration).days() === 0 &&
        Moment.duration(iso8601duration).hours() === 0 &&
        Moment.duration(iso8601duration).minutes() === 0 &&
        Moment.duration(iso8601duration).seconds() === 0) {
        return '-';
    } else if (Moment.duration(iso8601duration).days() > 0) {
        return `${Moment.duration(iso8601duration).days()}d ${Moment.duration(iso8601duration).hours()}h ${Moment.duration(iso8601duration).minutes()}m ${Moment.duration(iso8601duration).seconds()}s`;
    } else if (Moment.duration(iso8601duration).hours() > 0) {
        return `${Moment.duration(iso8601duration).hours()}h ${Moment.duration(iso8601duration).minutes()}m ${Moment.duration(iso8601duration).seconds()}s`;
    } else if (Moment.duration(iso8601duration).hours() === 0 &&
        Moment.duration(iso8601duration).minutes() > 0) {
        return `${Moment.duration(iso8601duration).minutes()}m ${Moment.duration(iso8601duration).seconds()}s`;
    } else if (Moment.duration(iso8601duration).minutes() === 0 &&
        Moment.duration(iso8601duration).seconds() > 0) {
        return `${Moment.duration(iso8601duration).seconds()}s`;
    } else {
        return `${Moment.duration(iso8601duration).minutes()}m ${Moment.duration(iso8601duration).seconds()}s`;
    }
};

export const FormatHhMmSs = (iso8601duration: string) => {
    var days = new Intl.NumberFormat('default', { minimumIntegerDigits: 2 }).format(Moment.duration(iso8601duration).days());
    var hours = new Intl.NumberFormat('default', { minimumIntegerDigits: 2 }).format(Moment.duration(iso8601duration).hours());
    var mins = new Intl.NumberFormat('default', { minimumIntegerDigits: 2 }).format(Moment.duration(iso8601duration).minutes());
    var secs = new Intl.NumberFormat('default', { minimumIntegerDigits: 2 }).format(Moment.duration(iso8601duration).seconds());

    if (Moment.duration(iso8601duration).days() !== 0) {
        return `${days}:${hours}:${mins}:${secs}`;
    } else if (Moment.duration(iso8601duration).hours() !== 0) {
        return `${hours}:${mins}:${secs}`;
    } else if (Moment.duration(iso8601duration).minutes() !== 0) {
        return `${mins}:${secs}`;
    } else {
        return `${mins}:${secs}`;
    }
};

export const FormatMinMaxAvg = (minmaxavg?: number): string => {
    if (minmaxavg === null) {
        return '-';
    } else {
        return `${new Intl.NumberFormat('default', { maximumFractionDigits: 2 }).format(minmaxavg)}`;
    }
};

export const FormatETA = (startDate: Date, endDate?: Date): string => {
    let currentDate = new Date();
    let start = new Date(startDate);
    const relativeTime = new RelativeTime();

    // First, determine if the date is in the past.
    if (currentDate > start) {
        // We're after the current start date.
        // Check if we're past the end date
        if (endDate)
        {
            let end = new Date(endDate);
            if (currentDate > end) {
                return "Event Passed";
            }
            else {
                return "Event Ongoing!";
            }
        }
        else {
            return "Event Ongoing!";
        }
    }
    else {
        //Event has yet to start up.
        return relativeTime.from(start);
    }
};

export const OrdinalSuffixOf = (i: number) => {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
};

const fraction = new Intl.NumberFormat('default', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});

const formatter = new Intl.NumberFormat('default', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

export const formatdecider = (input: number) => {
    if (input % 1 == 0) {
        return fraction.format(input);
    } else {
        return formatter.format(input);
    }
};

export const pcntgformat = (input: number) => {
    return `${new Intl.NumberFormat('default', { maximumFractionDigits: 3, minimumFractionDigits: 3 }).format(input)}`;
};

export const getEnumKeyByEnumValue = <T extends { [index: string]: string }>(myEnum: T, enumValue: string): keyof T | null => {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
};

export const str2bool = (value) => {
    if (value && typeof value === "string") {
        if (value.toLowerCase() === "true") return true;
        if (value.toLowerCase() === "false") return false;
    }
    return value;
};

// Check if the user has a role in one of the component roles
export const containsDuplicate = (componentroles: string[] | string, userroles: string[] | string) => {
    if (componentroles === undefined) {
        return true;
    }

    var comRoles = [];
    var usrRoles = [];

    if (!Array.isArray(componentroles)) {
        comRoles.push(componentroles);
    }
    else
    {
        comRoles = componentroles;
    }

    if (!Array.isArray(userroles)) {
        usrRoles.push(userroles);
    }
    else
    {
        usrRoles = userroles;
    }

    for (var i = 0; i < comRoles.length; i++) {
        for (var j = 0; j < usrRoles.length; j++) {
            if (usrRoles[j] === comRoles[i]) {
                return true;
            }
        }
    }
    return false;
};

export const navigateToReturnUrl = async (returnUrl: string) => {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.
    window.location.replace(returnUrl);
};

export const routeForResourceType = (type: PromotedType): string => {
    if (type === PromotedType.DoomCentralEvent) {
        return "event";
    } else if (type === PromotedType.ForumThread) {
        return "thread";
    } else if (type === PromotedType.NewsPost) {
        return "news";
    }
    return "";
};

export const routeForThreadType = (dto: IThreadListItemDto): string => {
    if (dto === null) {
        return "thread";
    }
    else if (dto.dcEvent != null) {
        return "event";
    }
    else if (dto.dcNews != null) {
        return "news";
    }
    else {
        return "thread";
    }
};

export const idForThreadType = (dto: IThreadListItemDto): number => {
    if (dto === null) {
        return 0;
    }
    else if (dto.dcNews != null) {
        return dto.dcNews.id;
    }
    else if (dto.dcEvent != null) {
        return dto.dcEvent.id;
    } else {
        return dto.id;
    }
};

export const colorForResourceType = (type: PromotedType): string => {
    if (type === PromotedType.DoomCentralEvent) {
        return "text-primary";
    } else if (type === PromotedType.ForumThread) {
        return "text-success";
    } else if (type === PromotedType.NewsPost) {
        return "text-warning";
    }
    return "";
};

export const IsResourceUniqueToPromotions = (promotion: IPromotedItemDto, promotions: IPromotedItemDto[]): boolean => {
    var noDuplicate = true;
    promotions.map((s, idx) => {
        if (s.promotedType === promotion.promotedType && s.resourceId === promotion.resourceId) {
            noDuplicate = false;
        }
    });
    return noDuplicate;
};

export const convertStringArray = (param: string | string[]): string[] => {
    return typeof param === 'string'
        ? [param]
        : param
};

export const notificationResponse = (type: PostNotificationType): string => {
    if (type === PostNotificationType.MentionOrQuoted) {
        return "mentioned your post on the thread";
    }
    else {
        return "replied to the subscribed thread";
    }
};

export const padTo2Digits = (num: string) => {
    return num.toString().padStart(2, '0');
};

export const FormatUTCISOToLocalISO = (utcDate: Date): string => {
    const localDateString = new Date(utcDate).toLocaleDateString();
    const [month, date, year] = localDateString.split('/');
    const hours = new Date(utcDate).getHours().toString();
    const minutes = new Date(utcDate).getMinutes().toString();
    const seconds = new Date(utcDate).getSeconds().toString();

    return `${year}-${padTo2Digits(month)}-${padTo2Digits(date)}T${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
};

export const UserCanEditForumPost = (user: User, post: IPostItemDto): boolean => {
    return true;
};

export const UserIsForumAdmin = (user: User, forumId: number): boolean => {
    if (!user) {
        return false;
    }

    if (user.profile.role as string[] &&
        containsDuplicate([
            ForumRoles.GlobalForumAdministrator,
            DoomCentralRoles.GlobalAdministrator
        ], user.profile.role as string[])) {
        return true;
    }

    return false;
};

export const UserIsNewsAdmin = (user: User, news: INewsPostDetailDto): boolean => {
    if (!user || !news) {
        return false;
    }

    if (user.profile.role as string[] &&
        containsDuplicate([
            NewsPostRoles.GlobalNewsAdministrator,
            DoomCentralRoles.GlobalAdministrator
        ], user.profile.role as string[])) {
        return true;
    }

    return false;
};

export const UserIsEventAdmin = (user: User, event: IEventDetailDto): boolean => {
    if (!user || !event) {
        return false;
    }

    if (user.profile.role as string[] &&
        containsDuplicate([
            DoomCentralEventRoles.GlobalEventAdministrator,
            DoomCentralRoles.GlobalAdministrator
        ], user.profile.role as string[])) {
        return true;
    }

    if (user.profile.role as string[] &&
        containsDuplicate(DoomCentralEventRoles.EventOrganizer, user.profile.role as string[])) {
        if (user.profile.sid === event.eventOrganizerId) {
            return true;
        }
    }

    return false;
};

export const UserCanPostInNewsThread = (user: User, news: INewsPostDetailDto): boolean => {
    if (!news || !user) {
        return false;
    }

    if (user.profile.role as string[] &&
        containsDuplicate([
            NewsPostRoles.GlobalNewsAdministrator,
            DoomCentralRoles.GlobalAdministrator
        ], user.profile.role as string[])) {
        return true;
    }

    if (news.locked) {
        return false;
    }

    if (user.profile.role as string[] &&
        containsDuplicate(DoomCentralRoles.DoomCentralUser, user.profile.role as string[])) {
        return true;
    }

    return false;
};

export const UserCanPostInEventThread = (user: User, event: IEventDetailDto): boolean => {
    if (!event || !user) {
        return false;
    }

    if (user.profile.role as string[] &&
        containsDuplicate([
            DoomCentralEventRoles.GlobalEventAdministrator,
            DoomCentralRoles.GlobalAdministrator
        ], user.profile.role as string[])) {
        return true;
    }

    if (event.locked === true) {
        return false;
    }

    if (user.profile.role as string[] &&
        containsDuplicate(DoomCentralRoles.DoomCentralUser, user.profile.role as string[])) {
        return true;
    }

    return false;
};

export const UserCanPostInThread = (user: User, thread: IThreadDetailDto): boolean => {
    if (!thread || !user) {
        return false;
    }

    if (user.profile.role as string[] &&
        containsDuplicate([
            ForumRoles.GlobalForumAdministrator,
            DoomCentralRoles.GlobalAdministrator
        ], user.profile.role as string[])) {
        return true;
    }

    if (thread.locked === true) {
        return false;
    }

    if (user.profile.role as string[] &&
        containsDuplicate(DoomCentralRoles.DoomCentralUser, user.profile.role as string[])) {
        return true;
    }

    return false;
};

export const formatBytes = (bytes: number, decimals: number = 2): string => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
};