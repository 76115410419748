import { setgroups } from 'process';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Alert, Button, Col, Fade, FormGroup, FormText, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';
import { CreateForumPermissionGroupCommand, ForumGrants, ForumGroupGrants, ForumPermissionGroupsClient, ForumPermissionLevel, IAllForumGroupDto, IForumGrants, IForumGroupGrants, IUserDto } from '../../../DoomCentral';
import { IErrorResponse } from '../../../ErrorResponse';
import { ErrorContext } from '../../../state';
import AddUserToPermissionsGroup from './AddUserToPermissionsGroup';
import { ForumPermission } from './ForumPermission';
import { IForumTreeObject } from './ForumPermissionTreeObject';
import GrantPanel from './GrantPanel';
import SelectForumAndGroup from './SelectForumAndGroup';

interface CreateNewForumPermissionGroupProps {
    setNewGroup: React.Dispatch<React.SetStateAction<number>>,
    forumTree: IForumTreeObject[],
    setForumTree: React.Dispatch<React.SetStateAction<IForumTreeObject[]>>,
}

export default (props: CreateNewForumPermissionGroupProps) => {
    const error = useContext(ErrorContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [groupName, setGroupName] = useState<string>("");
    const [groupUsers, setGroupUsers] = useState<IUserDto[]>([]);
    const [selectedTreeItem, setSelectedTreeItem] = useState<IForumTreeObject>(null);

    const submitForumPermissionGroup = async () => {
        setLoading(true);
        try {
            let client = new ForumPermissionGroupsClient();
            let command = new CreateForumPermissionGroupCommand();
            let users: string[] = [];
            let forumGrants: ForumGrants[] = [];
            let forumGroupGrants: ForumGroupGrants[] = [];

            groupUsers.map((s, idx) => {
                users = [...users, s.id];
            });

            props.forumTree.map((s, idx) => {
                if (s.permission !== ForumPermission.Inherit) {
                    let newGroupGrant: ForumGroupGrants = new ForumGroupGrants;
                    let groupperm: ForumPermissionLevel = ForumPermissionLevel.Reader;

                    if (s.permission === ForumPermission.Viewer) {
                        groupperm = ForumPermissionLevel.Reader;
                    } else if (s.permission === ForumPermission.Poster) {
                        groupperm = ForumPermissionLevel.Poster;
                    } else if (s.permission === ForumPermission.Moderator) {
                        groupperm = ForumPermissionLevel.Moderator;
                    }

                    newGroupGrant.forumGroupId = s.entityId;
                    newGroupGrant.permissionLevel = groupperm;
                    forumGroupGrants = [...forumGroupGrants, newGroupGrant];
                }

                s.children.map((t, _idx) => {
                    if (t.permission !== ForumPermission.Inherit) {
                        let newGrant: ForumGrants = new ForumGrants;
                        let perm: ForumPermissionLevel = ForumPermissionLevel.Reader;

                        if (t.permission === ForumPermission.Viewer) {
                            perm = ForumPermissionLevel.Reader;
                        } else if (t.permission === ForumPermission.Poster) {
                            perm = ForumPermissionLevel.Poster;
                        } else if (t.permission === ForumPermission.Moderator) {
                            perm = ForumPermissionLevel.Moderator;
                        }

                        newGrant.forumId = t.entityId;
                        newGrant.permissionLevel = perm;
                        forumGrants = [...forumGrants, newGrant];
                    }
                });
            });

            command.groupName = groupName;
            command.initialGroupMembers = users;

            if (forumGroupGrants.length > 0) {
                command.initialForumGroupGrants = forumGroupGrants;
            }

            if (forumGrants.length > 0) {
                command.initialForumGrants = forumGrants;
            }

            const response = await client.create(command)
                .then(response => response);
            props.setNewGroup(response);
        }
        catch (e) {
            error.setError(e.response as IErrorResponse);
        }
        setLoading(false);
    };

    const checkIfNewGroupAllowed = (): boolean => {
        if (groupName.length > 0 && groupUsers.length > 0) {
            let treeChanged: boolean = false;
            props.forumTree.map((s, idx) => {
                if (s.permission !== ForumPermission.Inherit) {
                    treeChanged = true;
                }

                s.children.map((t, _idx) => {
                    if (t.permission !== ForumPermission.Inherit) {
                        treeChanged = true;
                    }
                });
            });
            return treeChanged;
        }
        return false;
    };

    return (
        <React.Fragment>
            <Row className="mb-4">
                <Col xs={12}>
                    <InputGroup>
                        <InputGroupText>
                            Group Name
                        </InputGroupText>
                        <Input value={groupName} onChange={e => setGroupName(e.target.value)} />
                    </InputGroup>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col xs={12}>
                    <AddUserToPermissionsGroup loading={loading} users={groupUsers} setUsers={setGroupUsers} />
                </Col>
            </Row>
            <Row className="mb-4">
                <Col xs={6}>
                    <SelectForumAndGroup loading={loading} forumTree={props.forumTree} setSelectedTreeItem={setSelectedTreeItem} />
                </Col>
                <Col xs={6}>
                    <GrantPanel loading={loading} setSelectedTreeItem={setSelectedTreeItem} selectedTreeItem={selectedTreeItem} forumTree={props.forumTree} setForumTree={props.setForumTree} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button color="primary" onClick={e => submitForumPermissionGroup()} disabled={!checkIfNewGroupAllowed()}>
                        Submit New Forum Permission Group
                    </Button>
                </Col>
            </Row>
        </React.Fragment>
    );
};