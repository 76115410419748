import * as React from 'react';
import AuthorizeRoute from './api-authorization/AuthorizeRoute';
import { Route } from 'react-router';
import ThreadListing from './threadlisting/ThreadListing';
import ForumListing from './forumlisting/ForumListing';
import NewThread from './newthread/NewThread';
import ForumThread from './forumthread/ForumThread';
import ForumThreadListing from './forumthreadlisting/ForumThreadListing';
import DoomCentralRoles from '../DoomCentralRoles';

export default () => (
    <React.Fragment>
        <AuthorizeRoute path='/newthread' component={NewThread} componentroles={[DoomCentralRoles.DoomCentralUser]} />
        <Route exact path='/threads' component={ThreadListing} />
        <Route exact path='/forums' component={ForumListing} />
        <Route exact path='/thread/:id' component={ForumThread} />
        <Route exact path='/forum/:id' component={ForumThreadListing} />
    </React.Fragment>
);