import * as React from 'react';
import { Button, Row } from 'reactstrap';
import { IPromotableItemDto, IPromotedItemDto } from '../../DoomCentral';
import { IsResourceUniqueToPromotions } from '../../helpers';

interface AddToCurrentPromotionsProps {
    promotions: IPromotedItemDto[],
    setPromotions: React.Dispatch<React.SetStateAction<IPromotedItemDto[]>>,
    selectedResource: IPromotableItemDto,
    title: string,
    blurb: string,
    imageUrl: string,
    setModPromotion: React.Dispatch<React.SetStateAction<number>>
}

export default (props: AddToCurrentPromotionsProps) => {
    const addNewPromotion = () => {
        const promotion: IPromotedItemDto = {
            resourceId: props.selectedResource.resourceId,
            promotedType: props.selectedResource.promotedType,
            promotedBlurb: props.blurb,
            promotedTitle: props.title,
            promotedImageUrl: props.imageUrl
        };

        // Check if this promotion already exists
        if (!IsResourceUniqueToPromotions(promotion, props.promotions)) {
            return;
        }
        // Add to the bottom of a new list and set it.
        var newPromotions: IPromotedItemDto[] = [...props.promotions];
        newPromotions.push(promotion);

        props.setPromotions([...newPromotions]);
        props.setModPromotion(newPromotions.length + 1);
    };

    return (
        <React.Fragment>
            <Row className='my-2'>
                <Button color="primary" onClick={e => addNewPromotion()} disabled={!props.blurb || !props.imageUrl || !props.title || !props.selectedResource}>Add Promotion to List</Button>
            </Row>
        </React.Fragment>
    );
};
