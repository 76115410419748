import * as React from 'react';
import { useState } from 'react';
import QuoteBox from './QuoteBox';
import NewPostBoxContainer from './NewPostBoxContainer';
import SubmitPostButton from './SubmitPostButton';
import PostFileDropzone from './PostFileDropzone';
import { FileRejection } from 'react-dropzone';
import PostFilePreviewer from './PostFilePreviewer';
import PostFileRejections from './PostFileRejections';
import { PostFileUploadItem } from '../../DoomCentral';

interface PostStationProps {
    id: number,
    quotedPostId: number,
    setQuotedPostId: React.Dispatch<React.SetStateAction<number>>,
    setNewPostNum: React.Dispatch<React.SetStateAction<number>>,
    setGotoMode: React.Dispatch<React.SetStateAction<string>>,
    allowedFileExtensions: string,
    maxFileSizeBytes: number,
    maxFilesInPost: number
}

export default (props: PostStationProps) => {
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [fileRejects, setFileRejects] = useState<FileRejection[]>([]);
    const [descriptions, setDescriptions] = useState<PostFileUploadItem[]>([]);
    const [post, setPost] = useState<string>("");

    const removeFile = (fileName: string) => {
        if (!uploadedFiles.some(s => s.name === fileName)) {
            return;
        }

        var newFiles = [...uploadedFiles.filter(f => f.name === fileName)];

        setUploadedFiles(newFiles);
    };

    const updateDescription = (fileName: string, description: string): void => {
        var updatedDescriptions: PostFileUploadItem[] = [];

        if (descriptions.some(s => s.fileName === fileName)) {
            var newArray = descriptions.map(obj => {
                if (obj.fileName === fileName) {
                    var newDescription = new PostFileUploadItem();

                    newDescription.description = description;
                    newDescription.fileName = fileName;

                    return newDescription;
                }
                return obj;
            });

            updatedDescriptions = newArray;
        } else {
            var newDescription = new PostFileUploadItem();

            newDescription.description = description;
            newDescription.fileName = fileName;

            updatedDescriptions = [...descriptions, newDescription];
        }

        setDescriptions(updatedDescriptions);
    };

    return (
        <React.Fragment>
            <QuoteBox quotedPostId={props.quotedPostId} setQuotedPostId={props.setQuotedPostId} editMode={true} />
            <NewPostBoxContainer post={post} setPost={setPost} />
            <PostFileDropzone
                allowedFileExtensions={props.allowedFileExtensions}
                maxFilesInPost={props.maxFilesInPost}
                maxFileSizeBytes={props.maxFileSizeBytes}
                currentFiles={0}
                fileRejects={fileRejects}
                setFileRejects={setFileRejects}
                setUploadedFiles={setUploadedFiles}
                uploadedFiles={uploadedFiles}
            />
            <PostFilePreviewer
                removeFile={removeFile}
                uploadedFiles={uploadedFiles}
                descriptions={descriptions}
                updateDescription={updateDescription}
            />
            <PostFileRejections
                fileRejects={fileRejects}
            />
            <SubmitPostButton
                post={post}
                quotedPostId={props.quotedPostId}
                setPost={setPost}
                threadId={props.id}
                setMode={props.setGotoMode}
                setNewPostNum={props.setNewPostNum}
                setQuotedPostId={props.setQuotedPostId}
                uploadedFiles={uploadedFiles}
                setuploadedFiles={setUploadedFiles}
                descriptions={descriptions}
            />
        </React.Fragment>
    );
};