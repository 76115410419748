import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';
import { IThreadDetailDto } from '../../DoomCentral';
import ThreadModeratorTools from './ThreadModeratorTools';

interface ForumThreadHeaderProps {
    metaData?: IThreadDetailDto,
    isAdmin: boolean,
    setModThread: React.Dispatch<React.SetStateAction<number>>,
}

export default (props: ForumThreadHeaderProps) => {
    return (
        <React.Fragment>
            {props.metaData && (
                <div className='thread-header'>
                    <div className='thread-header-title'>
                        {props.metaData.title}
                    </div>
                    <div className='thread-header-description'>
                        posted in <NavLink to={`/forum/${props.metaData.forumId}`}>{props.metaData.forumName}</NavLink>
                    </div>
                    <div className='thread-header-modifiers'>
                        {!props.metaData.approved && (<i className="bi bi-exclamation-triangle-fill"></i>)}
                        {' '}
                        {props.metaData.moderated && (<i className="bi bi-shield-fill"></i>)}
                        {' '}
                        {props.metaData.hidden && (<i className="bi bi-search"></i>)}
                        {' '}
                        {props.metaData.locked && (<i className="bi bi-lock"></i>)}
                        {' '}
                        {props.metaData.stickied && (<i className="bi bi-sticky-fill"></i>)}
                    </div>
                </div>
            )}
            {props.metaData && props.isAdmin && (
                <div className='d-flex'>
                    <ThreadModeratorTools
                        setModThread={props.setModThread}
                        approved={props.metaData.approved}
                        hidden={props.metaData.hidden}
                        locked={props.metaData.locked}
                        stickied={props.metaData.stickied}
                        threadId={props.metaData.id}
                        moderated={props.metaData.moderated}
                    />
                </div>
            )}
        </React.Fragment>
    );
};