import * as React from 'react';
import { Button } from 'reactstrap';

interface DataSizePresetButtonsProps {
    maxFileSize: number,
    setMaxFileSize: React.Dispatch<React.SetStateAction<number>>,
}

export default (props: DataSizePresetButtonsProps) => {
    return (
        <React.Fragment>
            <Button onClick={e => props.setMaxFileSize(2097152)} disabled={props.maxFileSize === 2097152}>2 MB</Button>
            {' '}
            <Button onClick={e => props.setMaxFileSize(5242880)} disabled={props.maxFileSize === 5242880}>5 MB</Button>
            {' '}
            <Button onClick={e => props.setMaxFileSize(10485760)} disabled={props.maxFileSize === 10485760}>10 MB</Button>
            {' '}
        </React.Fragment>
    );
};