import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, Label } from 'reactstrap';
import { DeleteStreamerCommand, IStreamerDto, IStreamListVm, StreamerClient } from '../../../DoomCentral';
import { IErrorResponse } from '../../../ErrorResponse';
import { ErrorContext } from '../../../state';

interface DeleteStreamerProps {
    modStreamer: number,
    setModStreamer: React.Dispatch<React.SetStateAction<number>>
}

export default (props: DeleteStreamerProps) => {
    const error = useContext(ErrorContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [streamers, setStreamers] = useState<IStreamerDto[]>(null);
    const [selectedStreamer, setSelectedStreamer] = useState<IStreamerDto>(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new StreamerClient();
                const response = await client.get()
                    .then(response => response.toJSON() as Promise<IStreamListVm>);
                setStreamers(response.streamers);
                setSelectedStreamer(response.streamers[0] ?? null);
            } catch (e) {
                error.setError(e.response as IErrorResponse);
            }
            setLoading(false);
        };

        fetchData();

    }, [props.modStreamer]);

    const submitDeleteStreamer = async () => {
        setLoading(true);
        try {
            let client = new StreamerClient();
            let command = new DeleteStreamerCommand();
            command.streamId = selectedStreamer.id;
            const response = await client.delete(selectedStreamer.id, command)
                .then(response => response as number);
            props.setModStreamer(response);
        } catch (e) {
            error.setError(e.response as IErrorResponse);
        }
        setLoading(false);
    };

    return (
        <React.Fragment>
            <h2>Delete Streamer</h2>
            <FormGroup row>
                <Label>
                    Streamer:
                </Label>
                <Col sm={10}>
                    <Input type='select' value={selectedStreamer?.id ?? 0} onChange={e => setSelectedStreamer(streamers.find(f => f.id === parseInt(e.target.value, 10)) ?? streamers[0])} disabled={loading || !streamers || streamers.length <= 0}>
                        {streamers && streamers.map((s, idx) => (
                            <option value={s.id} key={s.id}>{s.channelName}</option>
                        ))}
                    </Input>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col>
                    <Button disabled={!streamers || streamers.length <= 0 || !selectedStreamer || loading} onClick={submitDeleteStreamer} color="danger">Delete Streamer</Button>
                </Col>
            </FormGroup>
        </React.Fragment>
    );
};