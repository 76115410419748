import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';
import { NewsPageMode } from './NewsListing';

interface NewsAdminProps {
    mode: NewsPageMode,
    setMode: React.Dispatch<React.SetStateAction<NewsPageMode>>
}

export default (props: NewsAdminProps) => {
    return (
        <React.Fragment>
            <div className='d-flex my-2'>
                <Button color="primary" className='me-2' onClick={e => props.setMode(NewsPageMode.AllNews)} active={props.mode === NewsPageMode.AllNews}>All News</Button>
                <Button color="primary" className='me-2' onClick={e => props.setMode(NewsPageMode.MyNews)} active={props.mode === NewsPageMode.MyNews}>My News</Button>
                <NavLink className='ms-auto' exact to={'/newnews'}>
                    <Button color="primary">New News Post</Button>
                </NavLink>
            </div>
        </React.Fragment>
    );
};