import { ErrorContext}  from '../state';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Alert, Fade } from 'reactstrap';

const ErrorMessage = () => {
    const error = useContext(ErrorContext);
    const [hidden, setHidden] = useState<boolean>(true);

    useEffect(() => {
        setHidden(false);
        const timer = setTimeout(() => {
            setHidden(true);
        }, 5000);
        return () => {
            clearTimeout(timer);
        };
    }, [error]);

    return (
        <React.Fragment>
            {error.error !== null && !(Object.keys(error.error).length === 0 && error.constructor === Object) && ( // javascript checking for empty object...
                <div className="fixed-top">
                    <Fade in={!hidden}>
                        <Alert color="danger" hidden={hidden}>
                            {error.error.title}
                            <br />
                            HTTP Status: {error.error.status}
                            <br />
                            {error.error.errors !== undefined && (Object.values(error.error.errors).map((value) => <p><>{value}</></p>))}
                            {error.error.detail && (<p>{error.error.detail}</p>)}
                        </Alert>
                    </Fade>
                </div>
            )}
        </React.Fragment>
    );
};

export default ErrorMessage