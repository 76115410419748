import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLocation } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { HashLink } from 'react-router-hash-link';
import { ThreadsClient, IPostPaginatedListVm, MarkThreadPostsAsReadCommand, PostViewMode } from '../../DoomCentral';
import { IErrorResponse } from '../../ErrorResponse';
import { ErrorContext } from '../../state';
import MarkdownPost from '../markdown-post/MarkdownPost';
import RelativeTime from '@yaireo/relative-time';
import ForumModeratorTools from './PostModeratorTools';
import QuoteBox from './QuoteBox';
import EditPostStation from './EditPostStation';
import ThreadPost from './ThreadPost';

interface ThreadPostListProps {
    id: number,
    loading: boolean, // only begin loading if the base page has stopped loading
    isAdmin: boolean, // has moderator status or better on specific thread
    authorized: boolean,
    pageNumber: number, // Controls pagination
    pageNumberCounter: number, // Doesn't control pagination
    pageSize: number,
    newPostNum: number,
    mode: string,
    postId?: number,
    setPageChange: (page: number, displayOnly: boolean) => void,
    setMode: React.Dispatch<React.SetStateAction<string>>,
    setPageCount: React.Dispatch<React.SetStateAction<number>>,
    setQuotedPostId: React.Dispatch<React.SetStateAction<number>>,
    setNewPostNum: React.Dispatch<React.SetStateAction<number>>,
}

export default (props: ThreadPostListProps) => {
    const error = useContext(ErrorContext);
    const auth = useAuth();
    const relativeTime = new RelativeTime();

    const [loading, setLoading] = useState<boolean>(false);

    const [editMode, setEditMode] = useState<boolean>(false);
    const [postToEdit, setPostToEdit] = useState<number>(0);
    const [quoteMode, setQuoteMode] = useState<boolean>(false);
    const [postToQuote, setPostToQuote] = useState<number>(0);

    const [editedPost, setEditedPost] = useState<string>("");
    const [editReason, setEditReason] = useState<string>("");

    const [postData, setPostData] = useState<IPostPaginatedListVm>(null);
    const [highestUnread, setHighestUnread] = useState<number>(0);

    const [modPost, setModPost] = useState<number>(0);

    // Async scrolling on load
    const scrolledRef = useRef(false);
    const { hash } = useLocation();
    const hashRef = useRef(hash);

    const editPost = (postId: number, quotedPostId: number, postContent: string) => {
        setEditMode(true);
        setPostToEdit(postId);
        setEditReason("");
        setEditedPost(postContent);
        setPostToQuote(quotedPostId);
    };

    const clearEditMode = () => {
        setEditMode(false);
        setPostToEdit(0);
        setEditReason("");
        setEditedPost("");
        setPostToQuote(0);
    };

    // Default page load mode: no page? Just load the first (or user pref)
    useEffect(() => {
        if (props.id > 0 && props.pageNumber <= 0 && (props.mode == null || props.mode.trim() === '') && props.postId === 0) {
            props.setPageChange(1, false);
        }
    }, [props.id]);

    // Hook to load posts based on changes to pagination/page size
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setHighestUnread(0);
            if (props.id > 0 && props.pageNumber > 0) {
                try {
                    let client = new ThreadsClient();
                    const response = await client.getPostsByThreadId(props.id, props.id, props.pageNumber, props.pageSize)
                        .then(response => response.toJSON() as Promise<IPostPaginatedListVm>);
                    props.setMode(null);
                    setPostData(response);
                    props.setPageCount(response.totalPages);
                    if (modPost > 0) {
                        setModPost(0);
                    }
                } catch (e) {
                    error.setError(e.response as IErrorResponse);
                }
            }
            setLoading(false);
        };

        fetchData();
    }, [props.pageNumber, props.pageSize, props.id]);

    // Hook to load posts based on mode changes.
    useEffect(() => {
        const fetchData = async () => {
            if (!props.loading &&
                props.id > 0 &&
                props.mode &&
                !(props.mode as PostViewMode === PostViewMode.Post &&
                    (props.postId === null || props.postId === 0))) {
                setLoading(true);
                setHighestUnread(0);
                try {
                    let client = new ThreadsClient();
                    const response = await client.getThreadPostsByParameters(props.mode as PostViewMode, props.id, props.postId)
                        .then(response => response.toJSON() as Promise<IPostPaginatedListVm>);
                    setPostData(response);
                    props.setPageCount(response.totalPages);
                    props.setPageChange(response.pageNumber, true);
                } catch (e) {
                    error.setError(e.response as IErrorResponse);
                }
                setLoading(false);
            }
        };

        fetchData();
    }, [props.mode, props.id]);

    // Hook to load latest post after posting
    useEffect(() => {
        if (!props.loading &&
            props.id > 0 && props.newPostNum > 0) {
            props.setMode(PostViewMode.LastPost);
        }
    }, [props.newPostNum]);

    // Hook to mark posts loaded as read
    useEffect(() => {
        const fetchData = async () => {
            if (postData && postData.highestPostId > highestUnread && postData.postList.length > 0 && auth.isAuthenticated) {
                try {
                    let client = new ThreadsClient();
                    const command = new MarkThreadPostsAsReadCommand();
                    command.threadId = props.id;
                    if (highestUnread <= 0) {
                        command.highestReadThreadPost = postData.highestPostId;
                    }
                    else {
                        command.highestReadThreadPost = highestUnread;
                    }
                    const response = await client.markAsRead(command)
                        .then(response => response as number);
                    setHighestUnread(response);
                } catch (e) {
                    error.setError(e.response as IErrorResponse);
                }
            }
        };

        fetchData();
    }, [postData]);

    // Scroll to hash on load
    useEffect(() => {
        if (hash) {
            // We want to reset if the hash has changed
            if (hashRef.current !== hash) {
                hashRef.current = hash;
                scrolledRef.current = false;
            }

            // only attempt to scroll if we haven't yet (this could have just reset above if hash changed)
            if (!scrolledRef.current) {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ behavior: 'auto' });
                    scrolledRef.current = true;
                }
            }
        }
    });

    // Hook to go to posts depending on mode
    useEffect(() => {
        if (props.mode) {
            var id = "";
            if (props.mode === "LastPost") {
                if (postData && postData.postList.length > 0) {
                    const highestId = Math.max(...postData.postList.map(o => o.id));
                    id = `postid-${highestId}`;
                }
            }
            else if (props.mode === "Unread") {
                if (postData && postData.postList.length > 0) {
                    const unread = postData.postList.find(f => f.unread === true)?.id ?? 0;
                    id = `postid-${unread}`;
                }
            }
            // We want to reset if the hash has changed
            if (hashRef.current !== id) {
                hashRef.current = id;
                scrolledRef.current = false;
            }

            // only attempt to scroll if we haven't yet (this could have just reset above if hash changed)
            if (!scrolledRef.current) {
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ behavior: 'auto' });
                    scrolledRef.current = true;
                }
            }
        }
    });

    // Hook to go to posts depending on postId
    useEffect(() => {
        if (props.postId && props.postId > 0) {
            const id = `postid-${props.postId}`;
            // We want to reset if the hash has changed
            if (hashRef.current !== id) {
                hashRef.current = id;
                scrolledRef.current = false;
            }

            // only attempt to scroll if we haven't yet (this could have just reset above if hash changed)
            if (!scrolledRef.current) {
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ behavior: 'auto' });
                    scrolledRef.current = true;
                }
            }
        }
    });

    return (
        <React.Fragment>
            {postData?.postList?.length > 0 && (
                <div>
                    {!loading && postData.postList.map((s, idx) => (
                        <ThreadPost
                            key={idx}
                            postItem={s}
                            authorized={props.authorized}
                            isAdmin={props.isAdmin}
                            pageNumber={props.pageNumberCounter}
                            editReason={editReason}
                            editedPost={editedPost}
                            postToEdit={postToEdit}
                            postToQuote={postToQuote}
                            editMode={editMode}
                            clearEditMode={clearEditMode}
                            editPost={editPost}
                            setModPost={setModPost}
                            setPostToQuote={setPostToQuote}
                            setQuotedPostId={props.setQuotedPostId}
                        />
                    ))}
                </div>
            )}
        </React.Fragment>
    );
};