import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { PostViewMode } from '../../DoomCentral';

interface UnreadControlProps {
    threadUrl: string,
    unreadPosts: number
}

export default (props: UnreadControlProps) => {
    return (
        <React.Fragment>
            {props.unreadPosts > 0 && (
                <NavLink to={`${props.threadUrl}?goto=${PostViewMode.Unread}`} className='thread-pages'>{props.unreadPosts} →</NavLink>
            )}
        </React.Fragment>
    );
};