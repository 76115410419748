import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { ForumGroupsClient, IForumDto, IForumGroupListVm, IForumGroupsDto } from '../../../../DoomCentral';
import { ErrorContext } from '../../../../state';
import CreateNewForumGroup from './CreateNewForumGroup';
import DeleteForumGroup from './DeleteForumGroup';
import ForumGroupSelect from './ForumGroupSelect';
import UpdateForumGroup from './UpdateForumGroup';

interface ManageForumGroupProps {
    setModGroup: React.Dispatch<React.SetStateAction<number>>,
    forumGroups: IForumGroupListVm
}

export default (props: ManageForumGroupProps) => {
    const error = useContext(ErrorContext);

    const [loading, setLoading] = useState<boolean>(false);

    const [selectedGroup, setSelectedGroup] = useState<IForumGroupsDto>(null);

    return (
        <React.Fragment>
            <Row>
                <h2>Forum Groups</h2>
                <Col>
                    <CreateNewForumGroup setNewGroup={props.setModGroup} />
                </Col>
                <Col>
                    <ForumGroupSelect selectedForum={null} forumGroups={props.forumGroups?.forumGroups} setGroupSelect={setSelectedGroup} loading={loading} />
                    <UpdateForumGroup selectedGroup={selectedGroup} setUpdatedGroup={props.setModGroup} loading={loading} />
                    <DeleteForumGroup selectedGroup={selectedGroup} setDeletedGroup={props.setModGroup} loading={loading} />
                </Col>
            </Row>
        </React.Fragment>
    );
};