import { useEffect } from 'react'
import { navigateToReturnUrl } from '../../helpers';
import { getReturnUrl } from './AuthHelpers';

const LogoutCallback = () => {
    useEffect(() => {
        (async () => {
            localStorage.clear();
            await navigateToReturnUrl(getReturnUrl(null));
        })();
    }, []);


    return (<div>Processing logout callback</div>);
}

export default LogoutCallback;