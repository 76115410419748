import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, FormGroup, FormText, Input, Label } from 'reactstrap';
import { DeleteForumGroupCommand, ForumGroupsClient, IForumGroupsDto } from '../../../../DoomCentral';
import { ErrorContext } from '../../../../state';

interface DeleteForumGroupProps {
    setDeletedGroup: React.Dispatch<React.SetStateAction<number>>,
    selectedGroup: IForumGroupsDto,
    loading: boolean
}

export default (props: DeleteForumGroupProps) => {
    const error = useContext(ErrorContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [clobber, setClobber] = useState<boolean>(false);

    const submitForumGroup = async () => {
        setLoading(true);
        try {
            let client = new ForumGroupsClient();
            let command = new DeleteForumGroupCommand();
            command.id = props.selectedGroup.id;
            command.clobber = clobber;

            const response = await client.delete(props.selectedGroup.id, command)
                .then(response => response);
            props.setDeletedGroup(response);
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
        setLoading(false);
    };

    return (
        <React.Fragment>
            <div className='d-flex'>
                <div className='ms-auto'>
                    <Button onClick={submitForumGroup} disabled={!props.selectedGroup || loading} color="danger" >
                        Delete Forum Group
                    </Button>
                </div>
            </div>
            <FormGroup row>
                <Label check
                    for='clobberForums'
                >
                    <Input type="checkbox" id='clobberForums' name='clobberForums' value={+clobber} onChange={(e) => setClobber(!!e.target.value)} /> {'   '}
                    Clobber (Delete all forums as well)
                </Label>
            </FormGroup>
        </React.Fragment>
    );
};