import * as React from 'react';
import { useState } from 'react';
import HelmetPages from '../../helmet/HelmetPages';
import AdminPanel from '../AdminPanel';
import CreateNewBadge from './CreateNewBadge';
import DeleteBadge from './DeleteBadge';

export default () => {
    const [modBadge, setModBadge] = useState<number>(0);

    return (
        <React.Fragment>
            <HelmetPages title={"Doom Central - Manage Badges"} />
            <AdminPanel>
                <h1>Manage Badges</h1>
                <CreateNewBadge setModBadge={setModBadge} />
                <hr />
                <DeleteBadge modBadge={modBadge} setModBadge={setModBadge} />
            </AdminPanel>
        </React.Fragment>
    );
};