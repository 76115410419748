import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { IUserDto, SetBanForUserCommand, UsersClient } from '../../../../DoomCentral';
import { IErrorResponse } from '../../../../ErrorResponse';
import { ErrorContext } from '../../../../state';

interface UserBanProps {
    selectedUser: IUserDto,
    setModUser: React.Dispatch<React.SetStateAction<string>>,
    setHidden: React.Dispatch<React.SetStateAction<boolean>>
}

export default (props: UserBanProps) => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [banned, setBanned] = useState<boolean>(false);

    useEffect(() => {
        if (props.selectedUser) {
            setBanned(props.selectedUser.banned);
        }

    }, [props.selectedUser]);

    const submitBan = async () => {
        if (props.selectedUser) {
            setLoading(true);
            try {
                let client = new UsersClient();
                const command = new SetBanForUserCommand;
                command.userId = props.selectedUser.id;
                command.banned = banned;
                const response = await client.setBanForUser(command)
                    .then(response => response as boolean);
                props.setModUser(props.selectedUser.id);
                props.setHidden(false);
                const timer = setTimeout(() => {
                    props.setHidden(true);
                }, 5000);
                return () => {
                    clearTimeout(timer);
                    setLoading(false);
                };
            } catch (e) {
                error.setError(e.response as IErrorResponse);
            }
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <p>User Bans</p>
            {props.selectedUser && (
                <Row>
                    <Col>
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox" checked={banned} onChange={(e) => setBanned(e.target.checked)} />
                                User Banned
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
            )}
            <Row>
                <Button color='primary' onClick={e => submitBan()}>
                    Submit Ban
                </Button>
            </Row>
        </React.Fragment>
    );
};