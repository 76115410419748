import * as React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './navmenu/NavMenu';
import ErrorMessage from './ErrorMessage';
import Footer from './footer/Footer';
import { ErrorProvider } from '../state';
import { AuthProvider } from "react-oidc-context";
import { oidcConfig } from '../configuration';
import { HelmetProvider } from 'react-helmet-async';
import 'bootstrap-icons/font/bootstrap-icons.css';

function Layout(props: { children?: React.ReactNode }) {
    return (
        <React.Fragment>
            <HelmetProvider>
                <AuthProvider {...oidcConfig}>
                    <Container fluid>
                        <NavMenu />
                        <ErrorProvider>
                            <ErrorMessage />
                            {props.children}
                        </ErrorProvider>
                        <Footer />
                    </Container>
                </AuthProvider>
            </HelmetProvider>
        </React.Fragment>
    );
}

export default Layout;