import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label} from 'reactstrap';
import { IStreamerDto, IStreamListVm, StreamerClient } from '../../DoomCentral';
import { IErrorResponse } from '../../ErrorResponse';
import { ErrorContext } from '../../state';

interface EventEditorStreamerProps {
    streamerId: number
    setStreamerId: React.Dispatch<React.SetStateAction<number>>,
}

export default (props: EventEditorStreamerProps) => {
    const error = useContext(ErrorContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [streamList, setStreamList] = useState<IStreamerDto[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new StreamerClient();
                const response = await client.get()
                    .then(response => response.toJSON() as Promise<IStreamListVm>);
                setStreamList(response.streamers);
            } catch (e) {
                error.setError(e.response as IErrorResponse);
            }
            setLoading(false);
        };

        fetchData();

    }, []);

    return (
        <React.Fragment>
            <FormGroup row>
                <Label for="streamerId">
                    Designated Event Streamer
                </Label>
                <Col sm={10}>
                    <Input type='select' name="streamerId" id="streamerId" value={props.streamerId} onChange={(e) => props.setStreamerId(parseInt(e.target.value, 10))} disabled={loading || !streamList || streamList.length <= 0}>
                        {streamList.length > 0 && streamList.map((s, idx) => (
                            <option key={s.id} value={s.id}>{s.channelName}</option>
                        ))}
                    </Input>
                </Col>
            </FormGroup>
        </React.Fragment>
    );
};