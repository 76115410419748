import * as React from 'react';
import { Col, Row } from 'reactstrap';
import AdminNav from './AdminNav';

const AdminPanel = (props: { children?: React.ReactNode }) => {
    return (
        <React.Fragment>
            <Row xs="1" sm="1" md="1" lg="2" xl="2" xxl="2" className='mt-md-5'>
                <Col lg="4" xl="4" xxl="4">
                    <AdminNav />
                </Col>
                <Col lg="8" xl="8" xxl="8" className='mx-auto my-2'>
                    {props.children}
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default AdminPanel;