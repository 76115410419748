import { NodeApi, NodeRendererProps } from "react-arborist";
import { ForumPermission } from "./ForumPermission";
import { IForumTreeObject } from "./ForumPermissionTreeObject";

const getNodeClasses = (node: NodeApi<IForumTreeObject>): string => {
    var classNames: string = "";

    if (node.children.length <= 0) {
        if (node.data.permission === ForumPermission.Inherit && node.parent.data.permission !== ForumPermission.Inherit) {
            if (node.parent.data.permission === ForumPermission.Moderator) {
                classNames += "bg-danger";
            } else if (node.parent.data.permission === ForumPermission.Viewer) {
                classNames += "bg-primary";
            } else if (node.parent.data.permission === ForumPermission.Poster) {
                classNames += "bg-success";
            }
        }
        else {
            if (node.data.permission === ForumPermission.Moderator) {
                classNames += "bg-danger";
            } else if (node.data.permission === ForumPermission.Viewer) {
                classNames += "bg-primary";
            } else if (node.data.permission === ForumPermission.Poster) {
                classNames += "bg-success";
            }
        }
    } else if (node.children.length > 0) {
        if (node.data.permission === ForumPermission.Moderator) {
            classNames += "bg-danger";
        } else if (node.data.permission === ForumPermission.Viewer) {
            classNames += "bg-primary";
        } else if (node.data.permission === ForumPermission.Poster) {
            classNames += "bg-success";
        }
    }

    return classNames;
};

export const ForumTreeNode = ({ node, style, dragHandle }: NodeRendererProps<IForumTreeObject>) => {
    return (
        <div style={style} className={getNodeClasses(node)} ref={dragHandle}>
            <div className="ms-auto px-3">
                {node.children.length > 0 ? (<i className="bi bi-collection-fill">{' '}</i>) : (<i className="bi bi-journal-text">{' '}</i>)}
                {node.data.name}{' '}
                {node.data.locked && (<i className="bi bi-lock">{' '}</i>)}
                {node.data.moderated && (<i className="bi bi-shield-exclamation">{' '}</i>)}
                {node.data.hidden && (<i className="bi bi-search">{' '}</i>)}
            </div>
        </div>
    );
};