import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { IForumDto, IForumGroupListVm, IForumGroupsDto } from '../../../../DoomCentral';
import { ErrorContext } from '../../../../state';
import ForumGroupSelect from '../forumgroup/ForumGroupSelect';
import CreateNewForum from './CreateNewForum';
import DeleteForum from './DeleteForum';
import ForumSelect from './ForumSelect';
import UpdateForum from './UpdateForum';

interface ManageForumProps {
    forumGroups: IForumGroupListVm,
    loading: boolean,
    modForum: number,
    setModForum: React.Dispatch<React.SetStateAction<number>>,
}

export default (props: ManageForumProps) => {
    const [selectedForum, setSelectedForum] = useState<IForumDto>(null);
    const [selectedForumGroup, setSelectedForumGroup] = useState<IForumGroupsDto>(null);

    return (
        <React.Fragment>
            <Row>
                <h2>Forums</h2>
            </Row>
            <Row>
                <Col>
                    <p>This controls the forum group for creating a new forum, and for editing an existing forum.</p>
                    <ForumGroupSelect forumGroups={props.forumGroups?.forumGroups} setGroupSelect={setSelectedForumGroup} selectedForum={selectedForum} loading={props.loading} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <CreateNewForum setNewForum={props.setModForum} forumGroup={selectedForumGroup} loading={props.loading} />
                </Col>
                <Col>
                    <ForumSelect modForum={props.modForum} setForumSelect={setSelectedForum} loading={props.loading} />
                    <UpdateForum selectedForum={selectedForum} selectedForumGroup={selectedForumGroup} setUpdatedForum={props.setModForum} loading={props.loading} />
                    <DeleteForum selectedForum={selectedForum} setDeletedForum={props.setModForum} loading={props.loading} />
                </Col>
            </Row>
        </React.Fragment>
    );
};