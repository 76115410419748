import * as React from 'react';
import { useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import TagsInput from 'react-tagsinput'

import 'react-tagsinput/react-tagsinput.css'
import DataCounter from './DataCounter';
import DataSizePresetButtons from './DataSizePresetButtons';
import DataTypePresetButtons from './DataTypePresetButtons';

interface PostFileSettingsProps {
    setTags: React.Dispatch<React.SetStateAction<any[]>>,
    tags: any[],
    setMaxFilesPerPost: React.Dispatch<React.SetStateAction<number>>,
    maxFilesPerPost: number,
    setMaxFileSize: React.Dispatch<React.SetStateAction<number>>,
    maxFileSize: number,
    loading: boolean
}

export default (props: PostFileSettingsProps) => {
    const [invalidTagNames, setInvalidTagNames] = useState<string>("");

    const handleChange = (tags: any[]) => {
        props.setTags(tags);
        setInvalidTagNames("");
    };

    const rejectTags = (tags: any[]) => {
        setInvalidTagNames(String(tags));
    };

    return (
        <React.Fragment>
            <FormGroup>
                <h5>Allowed File Extensions</h5>
                <TagsInput
                    value={props.tags}
                    onChange={handleChange}
                    onlyUnique={true}
                    validationRegex={new RegExp("^(\\.[a-zA-Z0-9]+)+$")}
                    onValidationReject={tags => rejectTags(tags)}
                />
                {invalidTagNames.length > 0 && (<div className="bg-danger text-white">The following attempted file extensions are invalid: {invalidTagNames}</div>)}
                {' '}
                <p className="text-muted">Please lead each file extension with .</p>
                <DataTypePresetButtons tags={props.tags} setTags={props.setTags} />
            </FormGroup>
            <FormGroup row>
                <Label for="fileSizeRange">
                    Maximum File Size Per Post File (in Bytes)
                </Label>
                <Input
                    id="fileSizeRange"
                    name="range"
                    type="range"
                    max={209715200}
                    min={0}
                    value={props.maxFileSize}
                    onChange={e => props.setMaxFileSize(parseInt(e.target.value))}
                />
                <DataCounter maxFileSize={props.maxFileSize} />
                <DataSizePresetButtons maxFileSize={props.maxFileSize} setMaxFileSize={props.setMaxFileSize} />
            </FormGroup>
            <FormGroup row>
                <Label for="filesPerPost">
                    Max Files Per Post
                </Label>
                <Input
                    id="filesPerPost"
                    name="number"
                    placeholder="Max Files Per Post"
                    type="number"
                    value={props.maxFilesPerPost}
                    onChange={e => props.setMaxFilesPerPost(parseInt(e.target.value))}
                />
            </FormGroup>
        </React.Fragment>
    );
};
