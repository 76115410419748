import * as React from 'react';
import { Col, Row } from 'reactstrap';
import ForumNav from '../forumnav/ForumNav';

import { countries } from '../../countries';
import { INewsItemsDto, IRecentThreadDto } from '../../DoomCentral';
import { NavLink } from 'react-router-dom';

interface SidebarProps {
    children?: React.ReactNode | React.ReactNode[],
    recentThreads?: IRecentThreadDto[],
    newsItems?: INewsItemsDto[],
    loading: boolean
}

export default (props: SidebarProps) => {
    return (
        <React.Fragment>
            <ForumNav />
            <Row xs="1" sm="1" md="1" lg="3" xl="3" xxl="3">
                <Col lg="2" xl="2" xxl="2" className='mb-2'>
                    <div className='module'>
                        <div className='module-header'>
                            Community News
                        </div>
                        {!props.loading && props.newsItems && props.newsItems.map((s, idx) => (
                            <NavLink to={`/news/${s.id}`} key={s.id}>
                                <div className='module-item'>
                                    <span className='module-item-flag flag'><i className="bi bi-newspaper"></i></span>
                                    <span className='module-item-title'>{s.title}</span>
                                    <span className='module-item-title text-muted'><>Posted on {new Intl.DateTimeFormat('default', { month: 'short', day: 'numeric' }).format(new Date(s.postDate))} by {s.postAuthor}</></span>
                                    <span className='module-item-newscount'>{s.posts}</span>
                                </div> 
                            </NavLink>
                        ))}
                        {props.loading && props.newsItems && props.newsItems.length <= 0 && (
                            <div className="placeholder-glow">
                                <div className='module-item placeholder-glow"'>
                                    <span className='module-item-title placeholder col-8'></span>
                                </div>
                                <div className='module-item'>
                                    <span className='module-item-title placeholder col-8'></span>
                                </div>
                                <div className='module-item'>
                                    <span className='module-item-title placeholder col-8'></span>
                                </div>
                            </div>
                        )}
                    </div>
                </Col>
                <Col lg="8" xl="8" xxl="8" className='mb-2'>
                    {props.children}
                </Col>
                <Col lg="2" xl="2" xxl="2" className='mb-2'>
                    <div className='module'>
                        <div className='module-header'>
                            Recent Threads
                        </div>
                        {!props.loading && props.recentThreads && props.recentThreads.map((s, idx) => (
                            <NavLink to={`/thread/${s.id}`} key={s.id}>
                                <div className='module-item'>
                                    {s.isSticky && (<span className='module-item-flag flag'><i className="bi bi-sticky"></i></span>)}
                                    <span className='module-item-title'>{s.title}</span>
                                    <span className='module-item-count'>{s.posts}</span>
                                </div>
                            </NavLink>
                        ))}
                        {props.loading && props.recentThreads && props.recentThreads.length <= 0 && (
                            <div className="placeholder-glow">
                                <div className='module-item'>
                                    <span className='module-item-title placeholder col-8'></span>
                                </div>
                                <div className='module-item'>
                                    <span className='module-item-title placeholder col-8'></span>
                                </div>
                                <div className='module-item'>
                                    <span className='module-item-title placeholder col-8'></span>
                                </div>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};