import { useEffect, useState } from 'react';
import { AuthContextProps } from 'react-oidc-context';
import { NavLink } from 'react-router-dom';
import { Table } from 'reactstrap';
import { IEventItemDto } from '../../DoomCentral';
import DoomCentralEventRoles from '../../DoomCentralEventRoles';
import DoomCentralRoles from '../../DoomCentralRoles';
import { containsDuplicate } from '../../helpers';
import { getEventDate, getEventTime, getFlagForEventRegion } from '../EventHelpers';

interface EventListProps {
    events: IEventItemDto[],
    auth: AuthContextProps
}

export default (props: EventListProps) => {
    const [isOrganizer, setIsOrganizer] = useState<boolean>(false);
    const [isGlobalAdmin, setIsGlobalAdmin] = useState<boolean>(false);

    useEffect(() => {
        if (props.auth.user &&
            props.auth.user?.profile.role as string[] &&
            containsDuplicate([
                DoomCentralEventRoles.GlobalEventAdministrator,
                DoomCentralEventRoles.EventOrganizer,
                DoomCentralRoles.GlobalAdministrator
            ], (props.auth.user?.profile.role as string[]))) {
            setIsOrganizer(true);
        }
        else {
            setIsOrganizer(false);
        }
    }, [props.auth]);

    useEffect(() => {
        if (props.auth.user &&
            props.auth.user?.profile.role as string[] &&
            containsDuplicate([
                DoomCentralEventRoles.GlobalEventAdministrator,
                DoomCentralRoles.GlobalAdministrator
            ], props.auth.user?.profile.role as string[])) {
            setIsGlobalAdmin(true);
        }
        else {
            setIsGlobalAdmin(false);
        }
    }, [props.auth]);
    return (
        <>
            {props.events && (
                <Table>
                    <thead>
                        <tr>
                            <th>
                                Title
                            </th>
                            <th>
                                Date
                            </th>
                            <th>
                                Time
                            </th>
                            <th>
                                Region
                            </th>
                            {(isOrganizer || isGlobalAdmin) && (<th>Edit</th>)}
                        </tr>
                    </thead>
                    {props.events.map((s, idx) => (
                        <tr key={s.eventId}>
                            <td><NavLink to={`/event/${s.eventId}`}>{s.title}</NavLink></td>
                            <td>{getEventDate(s.eventStartDate, s.eventEndDate)}</td>
                            <td>{getEventTime(s.eventStartDate, s.eventEndDate)}</td>
                            <td className='region'>{getFlagForEventRegion(s.eventRegion)}</td>
                            {((isOrganizer && props.auth.user && props.auth.user.profile.sid === s.organizerId) || isGlobalAdmin) && (<td><NavLink to={`/editevent?eventId=${s.eventId}`}><i className="bi bi-pencil-square"></i></NavLink></td>)}
                        </tr>
                    ))}
                </Table>
            )}
        </>
    );
};