import * as React from 'react';
import styled from 'styled-components'
import { useState, useCallback, useEffect } from 'react';
import { formatBytes } from '../../helpers';
import { Card, CardImg, CardImgOverlay, CardText, CardTitle, List } from 'reactstrap';
import { FileRejection } from 'react-dropzone';

interface PostFileRejectionProps {
    rejection: FileRejection
}

export default (props: PostFileRejectionProps) => {
    return (
        <React.Fragment>
            {props.rejection && (
                <List>
                    <li>
                        {props.rejection.file.name}
                        {props.rejection.errors && props.rejection.errors.length > 0 && (
                            <ul>
                                {props.rejection.errors.map((s, idx) => (
                                    <li className="text-danger">
                                        {s.message}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                </List>

            )}
        </React.Fragment>
    );
};
