import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, CloseButton, Collapse, NavLink, UncontrolledTooltip } from 'reactstrap';
import { IIndividualPostVm, ThreadPostsClient } from '../../DoomCentral';
import { IErrorResponse } from '../../ErrorResponse';
import { ErrorContext } from '../../state';
import RelativeTime from '@yaireo/relative-time';
import QuoteBox from './QuoteBox';
import EditPostBoxContainer from './EditPostBoxContainer';
import EditReasonSubmitCancel from './EditReasonSubmitCancel';

interface EditPostStationProps {
    id: number,
    postToQuote: number,
    setPostToQuote: React.Dispatch<React.SetStateAction<number>>,
    editedPost: string,
    editReason: string,
    clearEditMode: VoidFunction
}

export default (props: EditPostStationProps) => {
    const [post, setPost] = useState<string>("");
    const [editReason, setEditReason] = useState<string>("");

    useEffect(() => {
        setPost(props.editedPost);
    }, [props.editedPost]);

    useEffect(() => {
        setEditReason(props.editReason);
    }, [props.editReason]);

    return (
        <React.Fragment>
            <QuoteBox quotedPostId={props.postToQuote} setQuotedPostId={props.setPostToQuote} editMode={true} />
            <EditPostBoxContainer post={post} setPost={setPost} />
            <EditReasonSubmitCancel cancelEditMode={props.clearEditMode} editReason={editReason} setEditReason={setEditReason} editContent={post} />
        </React.Fragment>
    );
};