import * as React from 'react';
import { useContext, useState } from 'react';
import { Button, Table } from 'reactstrap';
import { DeleteDoomCentralEventCommand, DeleteNewsCommand, DoomCentralEventsClient, INewsListingItemDto, NewsClient, PublishNewsCommand, UnpublishNewsCommand } from '../../../DoomCentral';
import { IErrorResponse } from '../../../ErrorResponse';
import { ErrorContext } from '../../../state';

interface NewsTableProps {
    news: INewsListingItemDto[],
    setModNews: React.Dispatch<React.SetStateAction<number>>,
    loading: boolean
}

export default (props: NewsTableProps) => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);

    const deleteSubmit = async (newsId: number) => {
        setLoading(true);
        try {
            let client = new NewsClient();
            let command = new DeleteNewsCommand();
            command.newsId = newsId;
            const response = await client.delete(newsId, command)
                .then(response => response) as number;
            props.setModNews(response);
        } catch (e) {
            error.setError(e.response as IErrorResponse);
        }
        setLoading(false);
    };

    const publishSubmit = async (newsId: number) => {
        setLoading(true);
        try {
            let client = new NewsClient();
            let command = new PublishNewsCommand();
            command.newsId = newsId;
            const response = await client.delete(newsId, command)
                .then(response => response) as number;
            props.setModNews(response);
        } catch (e) {
            error.setError(e.response as IErrorResponse);
        }
        setLoading(false);
    };

    const unpublishSubmit = async (newsId: number) => {
        setLoading(true);
        try {
            let client = new NewsClient();
            let command = new UnpublishNewsCommand();
            command.newsId = newsId;
            const response = await client.delete(newsId, command)
                .then(response => response) as number;
            props.setModNews(response);
        } catch (e) {
            error.setError(e.response as IErrorResponse);
        }
        setLoading(false);
    };

    return (
        <React.Fragment>
            <Table>
                <tr>
                    <th>Id</th>
                    <th>Title</th>
                    <th>Created By</th>
                    <th>Published?</th>
                    <th>Publish Date</th>
                    <th>Publish</th>
                    <th>Delete</th>
                </tr>
                <tbody>
                    {props.news && props.news.map((s, idx) => (
                        <tr>
                            <td>{s.id}</td>
                            <td>{s.title}</td>
                            <td>{s.newsPublisherDisplayName}</td>
                            <td>{new Date(s.publishDate).toLocaleString()}</td>
                            <td><Button color="danger" onClick={e => deleteSubmit(s.id)} disabled={loading || props.loading}>Delete News</Button></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </React.Fragment>
    );
};