import * as React from 'react';
import { useEffect, useState } from 'react';
import { AuthContextProps } from 'react-oidc-context';
import { NavLink } from 'react-router-dom';
import { Table } from 'reactstrap';
import { INewsListingItemDto } from '../../DoomCentral';
import DoomCentralRoles from '../../DoomCentralRoles';
import { containsDuplicate } from '../../helpers';
import NewsPostRoles from '../../NewsPostRoles';

interface NewsListProps {
    newsItems: INewsListingItemDto[],
    auth: AuthContextProps
}

export default (props: NewsListProps) => {
    const [isAuthor, setIsAuthor] = useState<boolean>(false);
    const [isGlobalAdmin, setIsGlobalAdmin] = useState<boolean>(false);

    useEffect(() => {
        if (props.auth.user &&
            props.auth.user?.profile.role as string[] &&
            containsDuplicate([
                NewsPostRoles.GlobalNewsAdministrator,
                NewsPostRoles.NewsAuthor,
                DoomCentralRoles.GlobalAdministrator
            ], (props.auth.user?.profile.role as string[]))) {
            setIsAuthor(true);
        }
        else {
            setIsAuthor(false);
        }
    }, [props.auth]);

    useEffect(() => {
        if (props.auth.user &&
            props.auth.user?.profile.role as string[] &&
            containsDuplicate([
                NewsPostRoles.GlobalNewsAdministrator,
                DoomCentralRoles.GlobalAdministrator
            ], props.auth.user?.profile.role as string[])) {
            setIsGlobalAdmin(true);
        }
        else {
            setIsGlobalAdmin(false);
        }
    }, [props.auth]);

    return (
        <React.Fragment>
            <Table>
                <thead>
                    <tr>
                        <th>News</th>
                        <th>Author</th>
                        <th>Published</th>
                        {(isAuthor || isGlobalAdmin) && (<th>Edit</th>)}
                    </tr>
                </thead>
                <tbody>
                    {props.newsItems && props.newsItems.map((s, idx) => (
                        <tr key={s.id}>
                            <td><NavLink to={`/news/${s.id}`}>{s.title}</NavLink></td>
                            <td>by {s.newsPublisherUserName && (<NavLink to={`/user/${s.newsPublisherUserName}`}>{s.newsPublisherDisplayName}</NavLink>)}
                                {!s.newsPublisherUserName && (s.newsPublisherDisplayName)}
                            </td>
                            <td>{new Date(s.publishDate).toLocaleString()}</td>
                            {((isAuthor && props.auth.user && props.auth.user.profile.sid === s.newsPublisherId) || isGlobalAdmin) && (<td><NavLink to={`/editnews?newsId=${s.id}`}><i className="bi bi-pencil-square"></i></NavLink></td>)}
                            {((isAuthor && !isGlobalAdmin && props.auth.user && props.auth.user.profile.sid !== s.newsPublisherId)) && (<td></td>)}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </React.Fragment>
    );
};