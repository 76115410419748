import * as React from 'react';
import { FormGroup, FormText, Input, Label} from 'reactstrap';

interface NewsEditorPublishProps {
    publish: boolean,
    setPublish: React.Dispatch<React.SetStateAction<boolean>>
}

export default (props: NewsEditorPublishProps) => {
    return (
        <React.Fragment>
            <FormGroup row>
                <Label for="publish">
                    <Input id="publish" name="publish" type="checkbox" checked={props.publish} onChange={(e) => props.setPublish(e.target.checked)} />
                    {' '}
                    Publish this news item. This will make this event viewable to users.
                </Label>
            </FormGroup>
        </React.Fragment>
    );
};