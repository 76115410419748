import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AckNotificationCommand, DeleteNotificationCommand, IPostNotificationDto, NotificationsClient } from '../../DoomCentral';
import RelativeTime from '@yaireo/relative-time';
import { notificationResponse } from '../../helpers';
import NotificationPagination from './NotificationPagination';
import { ErrorContext } from '../../state';
import { IErrorResponse } from '../../ErrorResponse';

interface NotifificationsListProps {
    notifications: IPostNotificationDto[],
    pageCount: number,
    setPageNumber: React.Dispatch<React.SetStateAction<number>>,
    setModNotification: React.Dispatch<React.SetStateAction<number>>,
}

export default (props: NotifificationsListProps) => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);

    const relativeTime = new RelativeTime();

    const submitDeleteNotification = async (id: number) => {
        setLoading(true);
        try {
            let client = new NotificationsClient();
            let command = new DeleteNotificationCommand();
            command.notificationId = id;

            const response = await client.deleteUserNotification(command)
                .then(response => response);
            props.setModNotification(response);
        } catch (e) {
            error.setError(e.response as IErrorResponse);
        }
        setLoading(false);
    };

    const submitAckNotification = async (id: number) => {
        setLoading(true);
        try {
            let client = new NotificationsClient();
            let command = new AckNotificationCommand();
            command.notificationId = id;

            const response = await client.ackUserNotification(command)
                .then(response => response);
            props.setModNotification(response);
        } catch (e) {
            error.setError(e.response as IErrorResponse);
        }
        setLoading(false);
    };

    return (
        <React.Fragment>
            {props.notifications && (<NotificationPagination pageCount={props.pageCount} setPageChange={props.setPageNumber} />)}
            {props.notifications && props.notifications.map((s, idx) => (
                <Card
                    className="mx-2 not-item"
                    style={{
                        width: '18rem'
                    }}
                    id={s.id.toString()}
                    key={s.id.toString()}
                >
                    <CardHeader>
                        {relativeTime.from(new Date(s.created))}
                    </CardHeader>
                    <CardBody>
                        <CardTitle tag="h5">
                            {s.notifierName ?? 'Deleted User'} {notificationResponse(s.type)} {s.threadName}
                        </CardTitle>
                    </CardBody>
                    <CardFooter className='d-flex'>
                        <Button color='success' onClick={e => submitAckNotification(s.id)}>
                            <i className="bi bi-check-lg"></i>
                        </Button>
                        { }
                        <Button color='danger' className='ms-auto' onClick={e => submitDeleteNotification(s.id)}>
                            <i className="bi bi-trash"></i>
                        </Button>
                    </CardFooter>
                </Card>
            ))}
        </React.Fragment>
    );
};