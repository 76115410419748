import * as React from 'react';
import AuthorizeRoute from './api-authorization/AuthorizeRoute';
import DoomCentralRoles from '../DoomCentralRoles';
import EditPromotions from './editpromotions/EditPromotions';

export default () => (
    <React.Fragment>
        <AuthorizeRoute path='/editpromotions' component={EditPromotions} componentroles={[
            DoomCentralRoles.PromotionAdministrator,
            DoomCentralRoles.GlobalAdministrator
        ]} />
    </React.Fragment>
);
