import * as React from 'react';
import styled from 'styled-components'
import { useState, useCallback, useEffect } from 'react';
import { formatBytes } from '../../helpers';
import { Card, CardImg, CardImgOverlay, CardText, CardTitle, Input } from 'reactstrap';

interface PostFilePreviewProps {
    file: File,
    removeFile: (fileName: string) => void,
    description: string,
    updateDescription: (fileName: string, description: string) => void
}

export default (props: PostFilePreviewProps) => {
    const [imageUrl, setImageUrl] = useState<string>(""); // Image url to a thumbnail or the real uploaded thing.

    const getPreviewImageForFileName = (file: File): string => {
        var approvedImageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webm', '.webp'] as string[];
        if (approvedImageExtensions.some(s => file.name.endsWith(s))) {
            return URL.createObjectURL(file);
        }

        // Put other file types here.
        var zipExtensions = ['.zip', '.pk3', '.7z', '.rar'] as string[];
        if (zipExtensions.some(s => file.name.endsWith(s))) {
            return "/images/filetypes/filetypeplaceholder_zip.png"
        }

        return "/images/filetypes/filetypeplaceholder_any.png"
    };

    useEffect(() => {
        if (props.file) {
            setImageUrl(getPreviewImageForFileName(props.file));
        }

        return () => {
            URL.revokeObjectURL(imageUrl);
            setImageUrl("");
        };
    }, [props.file]);

    return (
        <React.Fragment>
            {props.file && (
                <React.Fragment>
                    <Card inverse>
                        <CardImg
                            alt={`Image preview for file ${props.file.name}`}
                            src={imageUrl}
                            style={{
                                height: 150
                            }}
                            width="100%"
                        />
                        <CardImgOverlay>
                            <CardTitle tag="h5">
                                {props.file.name}
                            </CardTitle>
                            <CardText>
                                <small className="text-muted">
                                    {formatBytes(props.file.size)}
                                </small>
                            </CardText>
                        </CardImgOverlay>
                    </Card>
                    <Input value={props.description} onChange={e => props.updateDescription(props.file.name, e.target.value)} placeholder="Add image description" />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};
