import * as React from 'react';
import { useContext, useState } from 'react';
import { Button } from 'reactstrap';
import { ApproveForumThreadCommand, ApproveThreadPostCommand, DeleteForumThreadCommand, LockThreadCommand, ModerateThreadCommand, StickyForumThreadCommand, ThreadPostsClient, ThreadsClient, UnlockThreadCommand, UnlockThreadPostCommand, UnmoderateThreadCommand, UnstickyForumThreadCommand } from '../../DoomCentral';
import { IErrorResponse } from '../../ErrorResponse';
import { ErrorContext } from '../../state';

interface ThreadModeratorToolsProps {
    threadId: number,
    setModThread: React.Dispatch<React.SetStateAction<number>>,
    locked: boolean,
    approved: boolean,
    stickied: boolean,
    hidden: boolean,
    moderated: boolean,
}

export default (props: ThreadModeratorToolsProps) => {
    const error = useContext(ErrorContext);

    const [loading, setLoading] = useState<boolean>(false);

    const lockThread = async () => {
        setLoading(true);
        try {
            let client = new ThreadsClient();
            let command = new LockThreadCommand();
            command.threadId = props.threadId

            const response = await client.lock(command)
                .then(response => response);
            props.setModThread(response);
        } catch (e) {
            error.setError(e.response as IErrorResponse);
        }
        setLoading(false);
    };

    const unlockThread = async () => {
        setLoading(true);
        try {
            let client = new ThreadsClient();
            let command = new UnlockThreadCommand();
            command.threadId = props.threadId

            const response = await client.unlock(command)
                .then(response => response);
            props.setModThread(response);
        } catch (e) {
            error.setError(e.response as IErrorResponse);
        }
        setLoading(false);
    };

    const moderateThread = async () => {
        setLoading(true);
        try {
            let client = new ThreadsClient();
            let command = new ModerateThreadCommand();
            command.threadId = props.threadId

            const response = await client.moderate(command)
                .then(response => response);
            props.setModThread(response);
        } catch (e) {
            error.setError(e.response as IErrorResponse);
        }
        setLoading(false);
    };

    const unmoderateThread = async () => {
        setLoading(true);
        try {
            let client = new ThreadsClient();
            let command = new UnmoderateThreadCommand();
            command.threadId = props.threadId

            const response = await client.unmoderate(command)
                .then(response => response);
            props.setModThread(response);
        } catch (e) {
            error.setError(e.response as IErrorResponse);
        }
        setLoading(false);
    };

    const unstickyThread = async () => {
        setLoading(true);
        try {
            let client = new ThreadsClient();
            let command = new StickyForumThreadCommand();
            command.threadId = props.threadId

            const response = await client.unsticky(command)
                .then(response => response);
            props.setModThread(response);
        } catch (e) {
            error.setError(e.response as IErrorResponse);
        }
        setLoading(false);
    };

    const stickyThread = async () => {
        setLoading(true);
        try {
            let client = new ThreadsClient();
            let command = new UnstickyForumThreadCommand();
            command.threadId = props.threadId

            const response = await client.sticky(command)
                .then(response => response);
            props.setModThread(response);
        } catch (e) {
            error.setError(e.response as IErrorResponse);
        }
        setLoading(false);
    };

    const approveThread = async () => {
        setLoading(true);
        try {
            let client = new ThreadsClient();
            let command = new ApproveForumThreadCommand();
            command.threadId = props.threadId

            const response = await client.approve(command)
                .then(response => response);
            props.setModThread(response);
        } catch (e) {
            error.setError(e.response as IErrorResponse);
        }
        setLoading(false);
    };

    const deleteThread = async () => {
        setLoading(true);
        try {
            let client = new ThreadsClient();
            let command = new DeleteForumThreadCommand();
            command.threadId = props.threadId

            const response = await client.delete(props.threadId, command)
                .then(response => response);
            props.setModThread(response);
        } catch (e) {
            error.setError(e.response as IErrorResponse);
        }
        setLoading(false);
    };

    return (
        <React.Fragment>
            <div className="ms-auto my-2 mx-4">
                {!props.approved && (
                    <Button className="moderator-btn" color="success" onClick={approveThread}>
                        <i className="bi bi-check"></i>
                    </Button>
                )}
                {' '}
                {props.moderated && (
                    <Button className="moderator-btn" color="danger" onClick={unmoderateThread}>
                        <i className="bi bi-shield-slash-fill"></i>
                    </Button>
                )}
                {' '}
                {!props.moderated && (
                    <Button className="moderator-btn" color="danger" onClick={moderateThread}>
                        <i className="bi bi-shield-fill"></i>
                    </Button>
                )}
                {' '}
                {props.locked && (
                    <Button className="moderator-btn" color="info" onClick={unlockThread}>
                        <i className="bi bi-unlock"></i>
                    </Button>
                )}
                {' '}
                {!props.locked && (
                    <Button className="moderator-btn" color="info" onClick={lockThread}>
                        <i className="bi bi-lock"></i>
                    </Button>
                )}
                {' '}
                {props.stickied && (
                    <Button className="moderator-btn" color="warning" onClick={unstickyThread}>
                        <i className="bi bi-sticky"></i>
                    </Button>
                )}
                {' '}
                {!props.stickied && (
                    <Button className="moderator-btn" color="warning" onClick={stickyThread}>
                        <i className="bi bi-sticky-fill"></i>
                    </Button>
                )}
                {' '}
                <Button className="moderator-btn" color="danger" onClick={deleteThread}>
                    <i className="bi bi-trash3-fill"></i>
                </Button>
            </div>
        </React.Fragment>
    );
};