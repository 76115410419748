import * as React from 'react';
import Sidebars from '../sidebars/Sidebars';
import ForumThreadHeader from './ForumThreadHeader';
import HelmetPages from '../helmet/HelmetPages';
import { useContext, useEffect, useState } from 'react';
import { ErrorContext } from '../../state';
import { ThreadsClient, IThreadViewVm } from '../../DoomCentral';
import { RouteComponentProps } from 'react-router';
import { IErrorResponse } from '../../ErrorResponse';
import { useAuth } from 'react-oidc-context';
import SingleMediaContainer from '../singlemediacontainer/SingleMediaContainer';
import { UserCanPostInThread, UserIsForumAdmin } from '../../helpers';

interface ForumThreadRouterProps {
    id: string;   // id of the thread (coming from the router)
}

interface ForumThreadProps extends RouteComponentProps<ForumThreadRouterProps> {
    // Add your regular properties here
}

export default (props: ForumThreadProps) => {
    const error = useContext(ErrorContext);

    const auth = useAuth();

    const [loading, setLoading] = useState<boolean>(false);
    const [modThread, setModThread] = useState<number>(0);

    const [forumThreadMetadata, setForumThreadMetadata] = useState<IThreadViewVm>();

    // Initial load for the thread page.
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new ThreadsClient();
                const response = await client.getThreadPageById(parseInt(props.match.params.id))
                    .then(response => response.toJSON() as Promise<IThreadViewVm>);
                setForumThreadMetadata(response);
                if (modThread > 0) {
                    setModThread(0);
                }
            } catch (e) {
                error.setError(e.response as IErrorResponse);
            }
            setLoading(false);
        };

        fetchData();

    }, [parseInt(props.match.params.id), modThread > 0]);

    return (
        <React.Fragment>
            <HelmetPages title={`Doom Central - Thread - ${forumThreadMetadata?.threadDetail?.title ?? "Loading..."}`} />
            <Sidebars loading={loading} newsItems={forumThreadMetadata?.newsItems} recentThreads={forumThreadMetadata?.recentThreads}>
                <div className='maincontent-container p-4'>
                    <SingleMediaContainer
                        loading={loading}
                        id={forumThreadMetadata?.threadDetail?.id ?? 0}
                        authorized={UserCanPostInThread(auth.user, forumThreadMetadata?.threadDetail)}
                        isAdmin={UserIsForumAdmin(auth.user, forumThreadMetadata?.threadDetail?.forumId ?? 0)}
                        maxFilesInPost={forumThreadMetadata?.threadDetail?.maxFilesPerPost ?? 0}
                        maxFileSizeBytes={forumThreadMetadata?.threadDetail?.maxFileSize ?? 0}
                        allowedFileExtensions={forumThreadMetadata?.threadDetail?.allowedFileExtensions ?? ""}
                    >
                        <ForumThreadHeader
                            metaData={forumThreadMetadata?.threadDetail}
                            isAdmin={UserIsForumAdmin(auth.user, forumThreadMetadata?.threadDetail?.forumId ?? 0)}
                            setModThread={setModThread}
                        />
                    </SingleMediaContainer>
                </div>
            </Sidebars>
        </React.Fragment>
    );
};