import * as React from 'react';
import styled from 'styled-components'
import PostFilePreview from './PostFilePreview';
import { PostFileDto, PostFileUploadItem } from '../../DoomCentral';
import PostFileDisplay from './PostFileDisplay';

interface PostFileDisplayerProps {
    postFiles: PostFileDto[],
    postId: number
}

export default (props: PostFileDisplayerProps) => {
    const removeFile = (id: number) => {

    };

    return (
        <React.Fragment>
            {
                props.postFiles &&
                props.postFiles.length > 0 && (
                    <PostFileDisplayerContainer>
                        {
                            props.postFiles.map((s, idx) => (
                                <PostFileDisplay
                                    postFile={s}
                                    postId={props.postId}
                                    removeFile={removeFile}
                                />
                            ))
                        }
                    </PostFileDisplayerContainer>
                )}
        </React.Fragment>
    );
};

const PostFileDisplayerContainer = styled.div`
    padding: 7px 10px;
    margin: 0;
    font-size: 13px;
    line-height: 16px;
    color: var(--bs-secondary-text-emphasis);
    background-color: var(--bs-secondary-bg);
    border: 1px solid var(--bs-secondary-color);
    border-top: 0;
    border-top-color: currentcolor;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
`;
