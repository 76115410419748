import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { SigninRedirectArgs } from "oidc-client-ts";
import { getReturnUrl } from "./AuthHelpers";

const LoginRedirect = () => {
    const auth = useAuth();
    var args = {} as SigninRedirectArgs;
    args.state = getReturnUrl(null);

    useEffect(() => {
        (async () => {
            await auth.signinRedirect(args);
        })();
    }, []);

    return (<div>Processing login</div>);
}

export default LoginRedirect;