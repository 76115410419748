import * as React from 'react';
import { useContext, useState } from 'react';
import { Button, Col, FormGroup, Input, Label } from 'reactstrap';
import { BadgesClient, FileParameter } from '../../../DoomCentral';
import { IErrorResponse } from '../../../ErrorResponse';
import { ErrorContext } from '../../../state';

interface CreateNewBadgeProps {
    setModBadge: React.Dispatch<React.SetStateAction<number>>
}

export default (props: CreateNewBadgeProps) => {
    const error = useContext(ErrorContext);

    const [badgeName, setBadgeName] = useState<string>("");
    const [badgeFile, setBadgeFile] = useState<FileParameter>();
    const [loading, setLoading] = useState<boolean>(false);

    const submitEmote = async () => {
        setLoading(true);
        try {
            let client = new BadgesClient();
            const response = await client.create(badgeName, badgeFile)
                .then(response => response) as number;
            props.setModBadge(response);
            setBadgeName("");
            setBadgeFile(null);
        } catch (e) {
            error.setError(e.response as IErrorResponse);
        }
        setLoading(false);
    };

    const handleSetFile = async (evt: React.ChangeEvent<HTMLInputElement>) => {
        const upload: FileParameter = {
            data: evt.target.files[0],
            fileName: evt.target.files[0].name
        };
        setBadgeFile(upload);
    };

    return (
        <React.Fragment>
            <h2>Create Badges</h2>
            <FormGroup row>
                <Label
                    for='badgeName'
                    sm={2}
                >
                    Badge Name
                </Label>
                <Col sm={10}>
                    <Input name='badgeName' value={badgeName} onChange={e => setBadgeName(e.target.value)} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label
                    for='badgeFile'
                    sm={2}
                >
                    Badge File
                </Label>
                <Col sm={10}>
                    <input type="file" id="badgeFile" name="badgeFile" accept="image/png, image/jpeg" onChange={handleSetFile} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col>
                    <Button disabled={!badgeName || !badgeFile || loading} onClick={submitEmote}>Submit Badge</Button>
                </Col>
            </FormGroup>
        </React.Fragment>
    );
};